import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// GetUp stores
import user from './users'
import codePackage from './codes-packages'
import lessons from './lessons'
import subjectsUnits from './subjects-units'
import examSessions from './exam-sessions'
import questionBank from './question-bank'
import settings from './settings'
import notifications from './notifications'
import ads from './ads'
import Invoice from './invoice'
import notificationsDash from './notificationDash'
import home from './home'

import responder from './responder'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // module getup store
    user,
    codePackage,
    lessons,
    subjectsUnits,
    Invoice,
    settings,
    examSessions, 
    questionBank,
    ads,
    notifications,
    notificationsDash,
    home,
    responder
  },
  strict: process.env.DEV,
})
