import { All } from "@/router";
import { getUserData } from "@/auth/utils";

export const canNavigate = 
    to => {
        const userData = getUserData()
        return to.meta(to).roles.indexOf(All) != -1 || (userData && to.meta(to).roles.indexOf(userData.role) != -1)
    }

export const _ = undefined
