import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
    isUserLoggedIn
} from "@/auth/utils";

import contentManagement from "./content-management";
import financaErnings from "./finance-earnings";
import settings from "./settings";
import users from "./users";

// responder dash route
import responder from "./responder";

Vue.use(VueRouter);

export const All = "all";
export const Admin = "Admin";
export const Teacher = "Teacher"
export const Responder = "Responder"

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        store.dispatch('app/setLoading', false)
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/home",
            name: "home",
            component: () => import("@/views/home/index.vue"),
            meta: () => ({
                pageTitle: "الرئيسية",
                icon: "",
                roles: [Admin],
                breadcrumb: [
                    {
                        text: "الرئيسية",
                        active: false
                    }
                ]
            })
        },
        ...contentManagement,
        ...financaErnings,
        ...settings,
        ...users,
        ...responder,
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login.vue"),
            meta: () => ({
                pageTitle: "GetUp تسجيل الدخول",
                layout: "full",
                roles: [All],
                redirectIfLoggedIn: true
            })
        },
        {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@/views/error/NotAuthorized.vue"),
            meta: () => ({
                pageTitle: "ممنوع الوصول",
                layout: "full",
                roles: [All]
            })
        },
        {
            path: "/server-error",
            name: "server error",
            component: () => import("@/views/error/server-error.vue"),
            meta: () => ({
                pageTitle: "خطأ في الإتصال",
                layout: "full",
                roles: [All]
            })
        },
        {
            path: "/error-404",
            name: "error 404",
            meta: () => ({
                pageTitle: "الصفحة غير موجودة",
                layout: "full",
                roles: [All]
            }),
            component: () => import("@/views/error/error-404.vue")
        },
        {
            path: "/",
            meta: () => ({
                pageTitle: "الرئيسية"
            }),
            redirect: "/home"
        },
        {
            path: "*",
            meta: () => ({
                pageTitle: "الصفحة غير موجودة"
            }),
            redirect: "error-404"
        }
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();
    document.title = !to.meta(to).breadcrumb
        ? !to.meta(to).pageTitle
            ? to.meta(to).title
            : to.meta(to).pageTitle
        : to.meta(to).breadcrumb[0].text;

    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
        isActive: false,
        isbuttonActive: true,
        text: "",
        fetchingFunction: null,
        placeHolder: "",
        value: "",
        filterFunc: null,
    });
    store.dispatch('app/setLoading', true)
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next("/login");
        
        // If logged in => not authorized
        return next("/access-denied");
    }
    // Redirect if logged in
    // if (to.meta(to).redirectIfLoggedIn && isLoggedIn) {
    //     const userData = getUserData();
    //     next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    // }

    return next();
});

export default router;
