import axiosIns from '@axios';
import store from '@/store';
import Swal from "sweetalert2";
const state = {
    isNotifiDialog: false,
    notifications: [],
    mainNotificationsList: [],
    notificationForm: {
        id: 0,
        title: "",
        body: "",
        notificationType: 0,
    },
    notifiPageLength: 10,
    notifiActivePage: [],
    notifiFirstPage: 1,
};
const getters = {
    notifications(state) {
        return state.notifications;
    },
    notificationForm(state) {
        return state.notificationForm;
    },
    isNotifiDialog(state) {
        return state.isNotifiDialog;
    },
    notifiPageLength(state) {
        return state.notifiPageLength;
    },
    notifiActivePage(state) {
        return state.notifiActivePage;
    },
    notifiFirstPage(state) {
        return state.notifiFirstPage;
    },
    mainNotificationsList(state) {
        return state.mainNotificationsList
    }
};
const mutations = {
    OPEN_NOTIFI_DIALOG(state, payload) {
        state.isNotifiDialog = payload;
    },
    NOTIFI_PAGINATION(state, currentPage) {
        store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.notifiPageLength,
                items: state.mainNotificationsList
            })
            .then(data => {
                state.notifiActivePage = data;
                state.notifiFirstPage = currentPage;
            })
    },
    GET_NOTIFICATION_ITEMS(state, payload) {
        state.notifications = payload;
        state.mainNotificationsList = [...payload];
        store.dispatch("notifiPagination", 1);
        state.notificationForm = {
            id: 0,
            title: "",
            body: "",
            notificationType: 0,
        };
    },
    ADD_NOTIFICATION(state, payload) {
        state.notifications.unshift(payload);
        state.mainNotificationsList.unshift(payload);
        store.dispatch("notifiPagination", 1);
        state.notificationForm = {
            id: 0,
            title: "",
            body: "",
            notificationType: 0,
        };
    },
    DELETE_NOTIFICATION_ITEMS(state, payload) {
        payload.forEach(id => {
            state.mainNotificationsList = state.mainNotificationsList.filter(item => item.id != id)
            state.notifications = state.notifications.filter(item => item.id != id)
        });
        Swal.fire({
            title: "تم الحذف !",
            text: `تم حذف الإشعارات بنجاح .`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000
        });
        store.dispatch("notifiPagination", 1);
    }
};
const actions = {
    toggleNotifiDialog(context, payload) {
        context.commit('OPEN_NOTIFI_DIALOG', payload);
    },
    notifiPagination(context, currentPage) {
        context.commit("NOTIFI_PAGINATION", currentPage)
    },
    async fetchNotifications(context) {
        const { data } = await axiosIns.get(`/Notification/GetAllNotifications`);
        context.commit('GET_NOTIFICATION_ITEMS', data)
    },
    async setNotification(context, payload) {
        const { data } = await axiosIns.post('/Notification/AddNotification', {
            Id: payload.id,
            Title: payload.title,
            Body: payload.body,
            NotificationType: payload.notificationType
        });
        context.commit('ADD_NOTIFICATION', data)
    },
    async deleteNotifications(context, notifiIds) {
         await axiosIns.put('/Notification/DeleteNotification', notifiIds);

        context.commit('DELETE_NOTIFICATION_ITEMS', notifiIds);
    },
    async filterNotifications({state}) {
        state.mainNotificationsList = await store.dispatch('app/filterItems', {items: 'notifications', key: 'title'})
        store.dispatch("notifiPagination", 1);
    }
};
const notification = { state, getters, mutations, actions };

export default notification;