import axiosIns from '@axios'
import router from "@/router";
import Swal from "sweetalert2";
import store from "@/store"


export default {
    state: {
        userList: [],
        activeTableUser: [],
        mainUserList: [],
        userPageLength: 10,
        userFirstPage: 1,
        userDto: {
            id: 0,
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            dob: new Date("1990-01-01").toISOString().substr(0, 10),
            password: '',
            phoneNumber: '',
            userType: 0,
            posAddress: '',
            moneyLimit: 0,
            discountAvailable: false,
            createdAt: new Date().toISOString().substr(0, 10),
            dateBlocked: null,
            rate: 0,
            subjectIds: [],
            count: 0
        },
        isCreateUserDialog: false,
        teacherUnitsList: [],
        studentCodesList: [],
        responderVideoAnswersList: [],
        posCodesList: [],
        detailsPageLength: 4,
        currentList: [],
        activeTableDetails: [],
        detailsFirstPage: 1,
    },
    getters: {
        userList(state) {
            return state.userList;
        },
        activeTableUser(state) {
            return state.activeTableUser;
        },
        userPageLength(state) {
            return state.userPageLength;
        },
        userFirstPage(state) {
            return state.userFirstPage;
        },
        userDto(state) {
            return state.userDto;
        },
        isCreateUserDialog(state) {
            return state.isCreateUserDialog
        },
        teacherUnitsList(state) {
            return state.teacherUnitsList;
        },
        studentCodesList(state) {
            return state.studentCodesList;
        },
        responderVideoAnswersList(state) {
            return state.responderVideoAnswersList
        },
        posCodesList(state) {
            return state.posCodesList
        },
        detailsPageLength(state) {
            return state.detailsPageLength;
        },
        activeTableDetails(state) {
            return state.activeTableDetails;
        },
        detailsFirstPage(state) {
            return state.detailsFirstPage;
        },
        currentList(state) {
            return state.currentList;
        },
        mainUserList(state) {
            return state.mainUserList
        }
    },
    mutations: {
        OPEN_CREATE_USER_DIALOG(state, is) {
            state.isCreateUserDialog = is
        },
        GET_USERS_ITEMS(state, payload) {
            console.log(payload)
            state.userList = payload.data.users
            state.mainUserList = [...payload.data.users]
            store.dispatch("updateUserPagination", 1)
        },
        CREATE_USER(state, payload) {
            state.userList.unshift(payload.user)
            state.mainUserList.unshift(payload.user)
            state.activeTableUser.unshift(payload.user)

            payload.vue.$bvToast.toast(`تم إضافة مستخدم ${state.userDto.firstName} بنجاح`, {
                title: "تمت الإضافة",
                variant: "success",
                toaster: "b-toaster-bottom-left",
                solid: true,
                autoHideDelay: 2000,
                appendToast: true,
            });

            Object.assign(state.userDto, {
                id: 0,
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                dob: new Date("1990-01-01").toISOString().substr(0, 10),
                password: "",
                phoneNumber: "",
                //userType : 0,
                moneyLimit: 0,
                posAddress: "",
                rate: 0,
                subjectIds: [],
                count: 0
            });
        },
        RESET_FORM(state) {
            Object.assign(state.userDto, {
                id: 0,
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                dob: new Date("1990-01-01").toISOString().substr(0, 10),
                password: "",
                phoneNumber: "",
                //userType : 0,
                moneyLimit: 0,
                posAddress: "",
                rate: 0,
                subjectIds: [],
                count: 0
            });
        },
        UPDATE_USER(state, payload) {
            payload.vue.$bvToast.toast(`تم تعديل مستخدم ${state.userDto.firstName} بنجاح`, {
                title: "تم التعديل",
                variant: "success",
                toaster: "b-toaster-bottom-left",
                solid: true,
                autoHideDelay: 2000,
                appendToast: true,
            });
            Object.assign(state.userDto, payload.user);
        },
        REMOVE_USER(state, payload) {
            console.log(payload)
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف ${payload.firstName}.`,
                icon: "success",
                confirmButtonText: "تم"
            });
            router.push(`/user/${payload.userType}`)
        },
        REMOVE_USERS(state, payload) {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
                MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.userList.length; idx++) {
                if (MapOfIds.has(state.userList[idx].id) === false) {
                    tempList.push(state.userList[idx]);
                }
            }
            state.userList = tempList;
            state.mainUserList = state.userList;

            store.dispatch("updateUserPagination", 1)
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف المستخدمين المحددين.`,
                icon: "success",
                confirmButtonText: "تم"
            });
        },
        CHANGE_BLOCKED(state, user) {
            state.userDto.dateBlocked = user.dateBlocked;
            if (user.dateBlocked != null) {
                Swal.fire({
                    title: "تم الحظر !",
                    text: `تم حظر ${user.fullName}`,
                    icon: "success",
                    confirmButtonText: "تم"
                });
            } else {
                Swal.fire({
                    title: "تم إلغاء الحظر !",
                    text: `تم حظر ${user.fullName}`,
                    icon: "success",
                    confirmButtonText: "تم"
                });
            }

        },
        UPDATE_USER_PAGINATION(state, currentPage) {
            store.dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.userPageLength,
                    items: state.mainUserList
                })
                .then(data => {
                    state.activeTableUser = data
                    state.userFirstPage = currentPage
                })
        },
        UPDATE_DETAILS_PAGINATION(state, payload) {
            store.dispatch("app/pageChanged", {
                    currentPage: payload,
                    pageLength: state.detailsPageLength,
                    items: state.currentList
                })
                .then(data => {
                    state.activeTableDetails = data
                    state.detailsFirstPage = payload
                })
        },
        GET_TEACHER_UNITS(state, payload) {
            Object.assign(state.userDto, payload.teacher);
            state.currentList = payload.units;
            store.dispatch("updateDetailsPagination", 1)
        },
        GET_STUDENT_CODES(state, payload) {
            console.log(payload.student)
            Object.assign(state.userDto, payload.student);
            state.currentList = payload.codes;
            store.dispatch("updateDetailsPagination", 1)
        },
        GET_POS_CODES(state, payload) {
            Object.assign(state.userDto, payload.pos);
            state.currentList = payload.posCodes
            store.dispatch("updateDetailsPagination", 1)
        },
        GET_Video_Answers(state, payload) {
            Object.assign(state.userDto, payload.responder);
            state.currentList = payload.answers
            store.dispatch("updateDetailsPagination", 1)
        },
        GET_ADMIN(state, payload) {
            Object.assign(state.userDto, payload.data.users[0]);
        },
        DUBLICATE_USER_MESSAGE() {
            Swal.fire({
                title: "تحذير",
                text: `اسم المستخدم مستخدم مسبقاً`,
                icon: "warning",
                confirmButtonText: "تم"
            });
        }
    },
    actions: {
        async fetchUsers(context, payload) {
            const {
                data
            } = await axiosIns
                .get(`User/GetUsers?id=${payload.id}&type=${payload.type}`)
                .then(data => data);
            context.commit("GET_USERS_ITEMS", {
                data: data,
                type: payload.type
            });
            console.log(data)
        },
        async createUser(context, payload) {
            console.log(payload)
            payload.user.rate = +payload.user.rate;
            payload.user.userType = +payload.user.userType
            payload.user.moneyLimit = +payload.user.moneyLimit
            const {
                data
            } = await axiosIns
                .post(`User/CreateUser`, payload.user)
                .then(data => data);

            if (data == "Exist") {
                context.commit("DUBLICATE_USER_MESSAGE");
            } else if (data == "NotFound") {
                Swal.fire({
                    title: ".اسم المستخدم غير صالح",
                    text: "اسم المستخدم يجب أن يتكون من أحرف انجليزية وأرقام فقط.",
                    icon: "warning",
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                context.commit("CREATE_USER", {
                    user: data,
                    vue: payload.vue
                });
            }
        },
        async updateUser(context, payload) {
            payload.user.rate = +payload.user.rate
            payload.user.moneyLimit = +payload.user.moneyLimit
            console.log(payload.user.rate)
            const {
                data
            } = await axiosIns
                .post(`User/UpdateUser`, payload.user)
                .then(data => data);
            if (data == "Exist") {
                context.commit("DUBLICATE_USER_MESSAGE");
            }
            // else if(data == "NotFound"){
            //   Swal.fire({
            //     title: ".اسم المستخدم غير صالح",
            //     text: "اسم المستخدم يجب أن يتكون من أحرف انجليزية وأرقام فقط.",
            //     icon: "warning",
            //     showConfirmButton: true,
            //     timer: 3000
            // });
            //}
            else {
                context.commit("UPDATE_USER", {
                    user: data,
                    vue: payload.vue
                });
            }
        },
        async removeUser(context, payload) {
            await axiosIns
                .delete(`User/RemoveUser?userId=${payload.id}`)
                .then(data => data);
            context.commit("REMOVE_USER", payload);
        },
        async removeUsers(context, userIds) {
            console.log(userIds);
            await axiosIns
                .put(`User/RemoveUsers`, userIds)
                .then(data => data);
            context.commit("REMOVE_USERS", userIds);
        },
        async changeBlocked(context, teacherId) {
            console.log(teacherId)
            const {
                data
            } = await axiosIns
                .get(`User/ChangeBlocked?id=${teacherId}`)
                .then(data => data);
            context.commit("CHANGE_BLOCKED", data);
            console.log(data)
        },
        toggleCreateUserDialog(context, is) {
            context.commit('RESET_FORM')
            context.commit('OPEN_CREATE_USER_DIALOG', is)
        },
        async fetchTeacherUnits(context, teacherId) {
            const {
                data
            } = await axiosIns
                .get(`User/GetTeacherUnits?id=${teacherId}`)
                .then(data => data);
            context.commit("GET_TEACHER_UNITS", data);
        },
        async fetchStudentCodes(context, studentId) {
            console.log(studentId)
            const {
                data
            } = await axiosIns
                .get(`Code/GetStudentCodes?studentId=${studentId}`)
                .then(data => data);
            context.commit("GET_STUDENT_CODES", data);
        },
        async fetchPosCodes(context, posId) {
            console.log(posId)
            const {
                data
            } = await axiosIns
                .get(`Code/GetPosCodes?posId=${posId}`)
                .then(data => data);
            context.commit("GET_POS_CODES", data);
        },
        async fetchVideoAnswers(context, responderId) {
            const {
                data
            } = await axiosIns
                .get(`Video/GetVideoAnswers?responderId=${responderId}`)
                .then(data => data);
            context.commit("GET_Video_Answers", data);
        },
        async fetchAdmin(context, payload) {
            const {
                data
            } = await axiosIns
                .get(`User/GetUsers?id=${payload.id}&type=${payload.type}`)
                .then(data => data);
            context.commit("GET_ADMIN", {
                data: data,
                type: payload.type
            });
            console.log(data)
        },
        updateUserPagination(context, currentPage) {
            context.commit("UPDATE_USER_PAGINATION", currentPage)
        },
        updateDetailsPagination(context, currentPage, type) {
            context.commit("UPDATE_DETAILS_PAGINATION", currentPage, type)
        },
        async filterUsers({ state }) {
            state.mainUserList = state.userList.filter((item) => {
                var x = item.firstName.trim() + " " + item.lastName.trim();
                x = x.trim().toLocaleLowerCase(); 
                return x.includes(store.state.app.createButtonAndGlobalSearch.value.toLocaleLowerCase());
            })
            store.dispatch("updateUserPagination", 1)
        }
    }
}