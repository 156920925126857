import axiosIns from '@axios';
import store from '@/store';
import Swal from "sweetalert2";
import router from '@/router';
export default {
    state: {
        questionbanksActivePage: [],
        questionbanksFirstPage: 1,
        questionbankPageLength: 10,
        questionBanks: [],
        mainQuestionBankList: [],
        questionBankForm: {
            id: 0,
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer5: "",
            rightAnswer: 0,
            videoId: null,
            answers: [],
            dateCreated: new Date().toISOString().substr(0, 10)
        },
        lessonsList: [],
        videosList: [],
        SelectedUnitList: {
            list: []
        }
    },
    getters: {
        questionBanks(state) {
            return state.questionBanks;
        },
        questionBankForm(state) {
            return state.questionBankForm;
        },
        lessonsList(state) {
            return state.lessonsList;
        },
        questionbanksActivePage(state) {
            return state.questionbanksActivePage;
        },
        questionbanksFirstPage(state) {
            return state.questionbanksFirstPage;
        },
        questionbankPageLength(state) {
            return state.questionbankPageLength;
        },
        videosList(state) {
            return state.videosList
        },
        SelectedUnitList(state) {
            return state.SelectedUnitList
        },
        mainQuestionBankList(state) {
            return state.mainQuestionBankList
        }
    },
    mutations: {
        QUESTONS_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.questionbankPageLength,
                    items: state.mainQuestionBankList
                })
                .then(data => {
                    state.questionbanksActivePage = data;
                    state.questionbanksFirstPage = currentPage;
                });
        },
        LESSONS_LIST(state, payload) {
            state.lessonsList = payload;
        },
        GET_QUESTIONBANK_ITEMS(state, payload) {
            Promise.all(
                payload.map((ques) => {
                    ques.answers = ques.answers.filter((item) => {
                        return !!item
                    })
                })
            ).then(() => {
                state.questionBanks = payload;
                state.mainQuestionBankList = [...payload];
                store.dispatch("questionsPagination", 1);
            })
        },
        GET_QUESTIONBANK_DETAILS(state, payload) {
            store.dispatch('resetFormQuestionBank')
            if (payload.questionBankDto) {
                Promise.all(
                    payload.questionBankDto.answers = payload.questionBankDto.answers.filter((item) => {
                        return !!item
                    })
                ).then(() => {
                    payload.questionBankDto.dateCreated = new Date(payload.questionBankDto.dateCreated).toISOString().substr(0, 10)
                    Object.assign(state.questionBankForm, payload.questionBankDto);
                })
            }
            state.videosList = payload.videosList;
        },
        ADD_QUESTION_BANK(state, payload) {
            state.questionBanks.unshift(payload);
            state.mainQuestionBankList.unshift(payload);
            store.dispatch("questionsPagination", 1);
            store.dispatch('resetFormQuestionBank')
        },
        REMOVE_QUESTIONBANK() {
            // state.questionBanks.forEach((question, index, list) => {
            //     if(ids.indexOf(question.id) != -1) {
            //         list.splice(index, 1);
            //     }
            // });
            // state.mainQuestionBankList.forEach((question, index, list) => {
            //     if(ids.indexOf(question.id) != -1) {
            //         list.splice(index, 1);
            //     }
            // });

            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف السؤال بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            router.push(`/question-bank`)
        },
        REMOVE_QUESTIONBANKS(state, payload) {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
                MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.mainQuestionBankList.length; idx++) {
                if (MapOfIds.has(state.mainQuestionBankList[idx].id) === false) {
                    tempList.push(state.mainQuestionBankList[idx]);
                }
            }
            state.mainQuestionBankList = tempList;
            state.questionbanksActivePage = state.mainQuestionBankList;

            store.dispatch("questionsPagination", 1)
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الأسئلة المحددة.`,
                icon: "success",
                confirmButtonText: "تم"
            });
        },
        ADD_QUESTION_ANSWER(state) {
            state.questionBankForm.answers.push({
                answer: "",
                isCorrect: false
            })
        },
        REMOVE_QUESTION_ANSWER(state, payload) {
            if (payload + 1 < state.questionBankForm.rightAnswer) {
                state.questionBankForm.rightAnswer--
            } else if (payload + 1 == state.questionBankForm.rightAnswer) {
                state.questionBankForm.rightAnswer = 0
            }
            state.questionBankForm.answers.splice(payload, 1)
        },
        RESET_QESTION_BANK_ANS(state) {
            state.questionBankForm = {
                id: 0,
                question: "",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answers: [],
                rightAnswer: 0,
                videoId: null,
                dateCreated: new Date().toISOString().substr(0, 10)
            };
        }
    },
    actions: {
        questionsPagination(context, currentPage) {
            context.commit('QUESTONS_PAGINATION', currentPage);
        },
        openQuestionBank() {
            router.push('/question-bank/0')
        },
        resetFormQuestionBank(context) {
            context.commit('RESET_QESTION_BANK_ANS')
        },
        async getLessonsList(context) {
            const {
                data
            } = await axiosIns.get('/Lesson/LessonsList');
            context.commit('LESSONS_LIST', data);
        },
        async fetchQuestionBanks(context, payload) {
            const {
                data
            } = await axiosIns.get(`/Video/GetQuestionBanks?videoId=${payload}`);
            context.commit('GET_QUESTIONBANK_ITEMS', data);
        },
        async fetchQuestionBankDetails(context, id) {
            const {
                data
            } = await axiosIns.get(`/Video/GetQuestionBankDetails?questionId=${ id }`);
            context.commit('GET_QUESTIONBANK_DETAILS', data);
        },
        async setQuestionBank(context, payload) {
            const params = {
                Id: payload.id,
                Question: payload.question,
                Answer1: !payload.answers[0] ? null : payload.answers[0].answer,
                Answer2: !payload.answers[1] ? null : payload.answers[1].answer,
                Answer3: !payload.answers[2] ? null : payload.answers[2].answer,
                Answer4: !payload.answers[3] ? null : payload.answers[3].answer,
                Answer5: !payload.answers[4] ? null : payload.answers[4].answer,
                RightAnswer: payload.rightAnswer,
                VideoId: payload.videoId,
            }
            console.log(params)
            const {
                data
            } = await axiosIns.post('/Video/SetQuestionBank', params)
            if (payload.id == 0) {
                context.commit('ADD_QUESTION_BANK', data);
            }
            // else {
            //     context.commit('UPDATE_QUESTION_BANK', data);
            // }
        },
        async removeQuestionBank(context, questionId) {
            console.log(questionId)
            await axiosIns.put('/Video/RemoveQuestionBanks', questionId)
            context.commit('REMOVE_QUESTIONBANK');
        },
        async removeQuestionBanks(context, questionIds) {
            console.log(questionIds)
            await axiosIns.put('/Video/RemoveQuestionBanks', questionIds)
            context.commit('REMOVE_QUESTIONBANKS', questionIds);
        },
        addQuestionAnswer(context) {
            context.commit('ADD_QUESTION_ANSWER')
        },
        removeQuestionAnswer(context, index) {
            context.commit('REMOVE_QUESTION_ANSWER', index)
        },
        async filterQuestionBank({
            state
        }) {
            state.mainQuestionBankList = await store.dispatch('app/filterItems', {
                items: 'questionBanks',
                key: 'question'
            })
            if (state.SelectedUnitList.list.length) {
                state.mainQuestionBankList = state.mainQuestionBankList.filter(question => {
                    return state.SelectedUnitList.list.indexOf(question.unitId) != -1
                })
            }
            store.dispatch("questionsPagination", 1);
        }
    }
}