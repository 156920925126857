import axiosIns from '@axios';
import Swal from "sweetalert2";
export default {
    state : {
        notificationsDash: [],
        notificationCount: 0,
    },
    getters : {
        notificationsDash(state) {
            return state.notificationsDash;
        },
        notificationCount(state) {
            return state.notificationCount;
        },
    },
    mutations : {
        GET_NOTIFICATIONS_DASH(state, payload) {
            state.notificationsDash = payload.dashNotifications;
            state.notificationCount = payload.notificationsCount;
        },
        REMOVE_NOTIFICATIONS_DASH(state, payload) {
            const index = state.notificationsDash.findIndex(item=> item.id == payload);
            state.notificationsDash.splice(index, 1);
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الإشعار بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 1500
            });
        },
        MARK_AS_READ(state) {
            state.notificationsDash.forEach(element => {
                element.isRead = true;
            });
            state.notificationCount = 0;
        }
    },
    actions : {
        async fetchNotificationsDash(context) {
            const { data } = await axiosIns.get(`Notification/GetDashNotification`);
            context.commit('GET_NOTIFICATIONS_DASH', data)
        },
        async removeNotificationsDash(context, payload) {
            payload = [payload]
            await axiosIns.put(`Notification/DeleteNotification` , payload);
            context.commit('REMOVE_NOTIFICATIONS_DASH' , payload)
        },
        async markAsRead(context) {
            await axiosIns.get(`Notification/SetRead`);
            context.commit('MARK_AS_READ')
        },
    }
}