import axiosIns from '@axios'
import store from '@/store'
import router from '@/router'
import Swal from "sweetalert2";

export default {
    state: {
        invoiceList: [],
        activeInvoiceList: [],
        activeTableInvoice: [],
        mainInvoiceList: [],
        breadcrumbUserName: '',
        invoicePageLength: 10,
        invoiceFirstPage: 1,
        invoiceDto: {
            id: 0,
            userId: 0,
            fullName: '',
            invoiceNumber: 2,
            invoiceDate: new Date(),
            totalValue: 0,
            paidValue: 0,
            notes: '',
            valueWithOutDiscount: 0,
            codeDtos: [{
                code: '',
                dateActivated: new Date().toISOString().substr(0, 10),
                rate: 0,
                isInclude: true,
                discountRate: 0,
                price: 0
            }],
            respondDtos: [{
                answerId: 0,
                answer: '',
                respondingDate: new Date().toISOString().substr(0, 10),
                lessonId: 0,
                lessonNumber: 0,
                unitId: 0,
                isInclude: true,
                unitName: '',
                price: 0
            }],
            teacherDtos: [{
                subjectId: 0,
                subjectName: '',
                rate: 0,
                unitId: 0,
                unitName: '',
                BaseNumberOfSubscriptions: 0,
                numberOfSubscriptions: 0,
                price: 0
            }]
        }
    },
    getters: {
        invoiceList(state) {
            return state.invoiceList
        },
        breadcrumbUserName(state) {
            return state.breadcrumbUserName
        },
        activeInvoiceList(state) {
            return state.activeInvoiceList
        },
        invoiceDto(state) {
            return state.invoiceDto
        },
        activeTableInvoice(state) {
            return state.activeTableInvoice
        },
        invoicePageLength(state) {
            return state.invoicePageLength
        },
        invoiceFirstPage(state){
            return state.invoiceFirstPage
        },
        mainInvoiceList(state) {
            return state.mainInvoiceList
        }
    },
    mutations: {
        RESET_LIST(state) {
            state.invoiceList = []
            state.mainInvoiceList = []
        },
        CREATE_INVOICE(){
            router.push(`/invoice/${router.currentRoute.params.type}/${router.currentRoute.params.userId}/add`)
        },
        GET_INVICE_DATA(state, Invoice) {
            state.invoiceList = Invoice
            state.activeInvoiceList = Invoice
            state.mainInvoiceList = [...Invoice]
            store.dispatch("updateInvoicePagination", 1)
        },
        GET_USRE_INVOICE(state, id) {
            const interv = setInterval(async () => {
                if(state.invoiceList.length) {
                    const list = await state.invoiceList.filter((item) => {
                        return item.id == id
                    })[0]
                    state.activeInvoiceList = list.userInvoiceDetailsDtos
                    state.mainInvoiceList = list.userInvoiceDetailsDtos
                    state.breadcrumbUserName = list.userName
                    store.dispatch("updateInvoicePagination", 1)
                    clearInterval(interv) 
                }
            })
        },
        FILL_FORM_ADD(state, invoice) {
            state.invoiceDto = invoice
            state.invoiceDto.paidValue = invoice.totalValue;
        },
        FILL_FORM_UPDATE(state, invoice) {
            state.invoiceDto = invoice
        },
        REMOVE_INVOICE_ITEM(state, payload) {
            if(payload.type == 1) {
                if(!payload.is) {
                    state.invoiceDto.teacherDtos[payload.index].numberOfSubscriptions =
                    !state.invoiceDto.teacherDtos[payload.index].numberOfSubscriptions
                    ? state.invoiceDto.teacherDtos[payload.index].baseNumberOfSubscriptions
                    : 0
                }
                state.invoiceDto.codeCounts = 0
                state.invoiceDto.totalValue = 0
                state.invoiceDto.valueWithOutDiscount = 0
                state.invoiceDto.teacherDtos.map((subsecription) => {
                    state.invoiceDto.codeCounts += +subsecription.numberOfSubscriptions
                    state.invoiceDto.totalValue +=
                        subsecription.numberOfSubscriptions
                        * subsecription.price
                        * (subsecription.rate/100)
                    state.invoiceDto.valueWithOutDiscount +=
                        subsecription.numberOfSubscriptions
                        * subsecription.price
                })
            } else if(payload.type == 2) {
                state.invoiceDto.respondDtos[payload.index].isInclude = !state.invoiceDto.respondDtos[payload.index].isInclude
                state.invoiceDto.codeCounts = 0
                state.invoiceDto.totalValue = 0
                state.invoiceDto.respondDtos.map((answer) => {
                    if(answer.isInclude) {
                        state.invoiceDto.codeCounts++
                        state.invoiceDto.totalValue += answer.price
                    }
                })
            } else if(payload.type == 3) {
                let x = state.invoiceDto.codeDtos[payload.index].isInclude
                console.log(state.invoiceDto.codeDtos[payload.index])
                state.invoiceDto.codeDtos[payload.index].isInclude = !x
                state.invoiceDto.valueWithOutDiscount = 0
                state.invoiceDto.totalValue = 0
                state.invoiceDto.codeCounts = 0
                state.invoiceDto.codeDtos.map((code) => {
                    if(code.isInclude) {
                        state.invoiceDto.codeCounts++
                        state.invoiceDto.valueWithOutDiscount += code.price
                        state.invoiceDto.totalValue += (code.price - code.price * (code.discountRate / 100)) * (1 - code.posRate/100)
                    }
                })
            }
        },
        CREATE_INVOIDE(state, payload) {
            if (payload.isPrint) {
                router.push('../' + payload.userId + '/' + payload.invoiceNumber + '/print')
            } else {
                router.push('../' + payload.userId)
            }
        },
        UPDATE_INVOICE_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.invoicePageLength,
                    items: state.mainInvoiceList
                })
                .then(data => {
                    state.activeTableInvoice = data
                    state.invoiceFirstPage = currentPage
                })
        },
        REMOVE_INVOICE(state , payload){
            if(payload == true){
                Swal.fire({
                    title: "تم الحذف !",
                    text: `تم حذف الفاتورة.`,
                    icon: "success",
                    confirmButtonText: "تم"
                  });
                  router.push("../")   
            }
            else{
                Swal.fire({
                    title: "لا يمكن الحذف !",
                    text: `يمكن فقط حذف آخر فاتورة.`,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }
    },
    actions: {
        createInvoce(context) {
            context.commit('CREATE_INVOICE')
        },
        updateInvoicePagination(context, currentPage) {
            context.commit("UPDATE_INVOICE_PAGINATION", currentPage)
        },
        removeInvoiceItem(context, payload) {
            context.commit('REMOVE_INVOICE_ITEM', payload)
        },
        async removeInvoice(context,id){
            const { data } = await axiosIns.delete(`Invoice/RemoveInvoice?id=${id}`)
                .then(data => data);
            context.commit('REMOVE_INVOICE' , data)
        },
        getUserInvoice(context, id) {
            context.commit('GET_USRE_INVOICE', id)
        },
        async getInvoice(context, type) {
            context.commit('RESET_LIST')
            await axiosIns.get(`Invoice/GetAllInvoices?type=${type}`)
                .then((data) => {
                    context.commit('GET_INVICE_DATA', data.data)
                }).catch((error) => {
                    console.log(error)
                })
        },
        fillForm(context, payload) {
            axiosIns.post(`Invoice/FillInvoiceForm?id=${payload.id}&type=${payload.type}`)
                .then((data) => {
                    context.commit('FILL_FORM_ADD', data.data)
                }).catch((error) => {
                    console.log(error)
                })
        },
        submitInvoiceForm(context, payload) {
            console.log(payload)
            payload.dto.userId = +payload.userId
            payload.dto.paidValue = +payload.dto.paidValue
            axiosIns.post(`Invoice/CreateUserInvoice?type=${payload.type}`, payload.dto)
            .then(() => {
                context.commit('CREATE_INVOIDE', {userId: payload.userId, invoiceNumber: payload.dto.invoiceNumber, isPrint: payload.isPrint})
            }).catch((error) => {
                console.log(error)
            })
        },
        getInvoiceByid(context, payload){
            axiosIns.get(`Invoice/GetInvoices?personId=${payload.id}&type=${payload.type}&invoiceId=${payload.invoiceId}`)
            .then((data) => {
                context.commit('FILL_FORM_UPDATE', data.data[0])
            }).catch((error) => {
                console.log(error)
            })
        },
        async filterInvoice({state}) {
            if(!router.currentRoute.params.userId) {
                state.mainInvoiceList = await store.dispatch('app/filterItems', {items: 'activeInvoiceList', key: 'userName'})
            } else {
                state.mainInvoiceList = await store.dispatch('app/filterItems', {items: 'activeInvoiceList', key: 'invoiceNumber'})
            }
            store.dispatch("updateInvoicePagination", 1);
        }
    }
}
