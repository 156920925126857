import { Admin } from "../index"
import store from "@/store";
export default [
    {
        path: "/codes-packages",
        name: "activation codes",
        props: true,
        component: () => import("@/views/finance-earnings/codes-packages"),
        redirect: "/codes-packages/codes",
        children: [
            {
                path: "codes",
                name: "codes",
                component: () =>
                    import(
                        "@/views/finance-earnings/codes-packages/components/codesTable"
                    ),
                meta: () => ({
                    pageTitle: "الرموز",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            icon: "qrcode-scan",
                            text: "رموز التفعيل",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "packages",
                name: "packages",
                component: () =>
                    import(
                        "@/views/finance-earnings/codes-packages/components/packagesTable"
                    ),
                meta: () => ({
                    pageTitle: "الحزم",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            icon: "box",
                            text: "الحزم المتوفرة",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "packages/:id",
                name: "packages details",
                props: true,
                component: () =>
                    import(
                        "@/views/finance-earnings/codes-packages/components/package-details"
                    ),
                meta: () => ({
                    pageTitle: "الحزم",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            icon: "box",
                            text: "الحزم المتوفرة",
                            to: "/codes-packages/packages",
                            active: false
                        },
                        {
                            icon: "pen",
                            text: "تفاصيل الحزمة",
                            active: true
                        }
                    ]
                })
            }
        ]
    },
    {
        path: "/invoice",
        component: () => import("@/views/finance-earnings/invoice"),
        children: [
            {
                path: "",
                name: "invoice default",
                redirect: "/invoice/3"
            },
            {
                path: ":type/:userId?",
                name: "invoices",
                props: true,
                meta: route => {
                    if (route.params && route.params.type == 3 && !route.params.userId) {
                        return {
                            pageTitle: "كشف حساب",
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: "كشف حساب نقاط البيع",
                                    active: true
                                }
                            ]
                        };
                    } else if (route.params && route.params.type == 2 && !route.params.userId) {
                        return {
                            pageTitle: "كشف حساب",
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: "كشف حساب مجيبو الأسئلة",
                                    active: true
                                }
                            ]
                        };
                    } else if (route.params && route.params.type == 1 && !route.params.userId) {
                        return {
                            pageTitle: "كشف حساب",
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: "كشف حساب الأساتذة",
                                    active: true
                                }
                            ]
                        };
                    } else if (route.params && route.params.userId != undefined) {
                        return {
                            pageTitle: "كشف حساب",
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: "كشف حساب",
                                    to: "/invoice/" + (!route.params ? 0 : route.params.type),
                                    active: false
                                },
                                {
                                    text: `فواتير ${store.getters.breadcrumbUserName}`,
                                    active: true
                                }
                            ]
                        };
                    } else {
                        return {
                            pageTitle: "كشف حساب",
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: "كشف حساب",
                                    active: true
                                }
                            ]
                        };
                    }
                },
                component: () =>
                    import(
                        "@/views/finance-earnings/invoice/components/invoice-table.vue"
                    )
            },
            {
                path: ":type/:userId/add",
                name: "invoice add",
                props: true,
                meta: route => ({
                    pageTitle: "كشف حساب",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            text: "كشف حساب",
                            to: "/invoice/" + (!route.params ? 1 : route.params.type),
                            active: false
                        },
                        {
                            text: `فواتير ${store.getters.breadcrumbUserName}`,
                            to:
                                "/invoice/" +
                                (!route.params ? 1 : route.params.type) +
                                "/" +
                                (!route.params ? 0 : route.params.userId),
                            active: false
                        },
                        {
                            text: "إضافة فاتورة",
                            active: true
                        }
                    ]
                }),
                component: () =>
                    import(
                        "@/views/finance-earnings/invoice/components/add-invoice.vue"
                    )
            },
            {
                path: ":type/:userId/:invoiceId/details",
                name: "invoice details",
                props: true,
                meta: route => ({
                    pageTitle: "كشف حساب",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            text: "كشف حساب",
                            to: "/invoice/" + (!route.params ? 1 : route.params.type),
                            active: false
                        },
                        {
                            text: `فواتير ${store.getters.breadcrumbUserName}`,
                            to:
                                "/invoice/" +
                                (!route.params ? 1 : route.params.type) +
                                "/" +
                                (!route.params ? 0 : route.params.userId),
                            active: false
                        },
                        {
                            text: "تفاصيل فاتورة",
                            active: true
                        }
                    ]
                }),
                component: () =>
                    import(
                        "@/views/finance-earnings/invoice/components/details-invoice.vue"
                    )
            },
            {
                path: ":type/:userId/:invoiceId/print",
                name: "invoice print",
                props: true,
                meta: route => {
                    if (route.params && route.params.type == 3) {
                        return {
                            roles: [Admin],
                            title:
                                "كشف حساب-حساب نقاط البيع-(" +
                                new Date().toISOString().substr(0, 10) +
                                ")"
                        };
                    } else if (route.params && route.params.type == 2) {
                        return {
                            roles: [Admin],
                            title:
                                "كشف حساب-مجيبو الأسئلة-(" +
                                new Date().toISOString().substr(0, 10) +
                                ")"
                        };
                    } else if (route.params && route.params.type == 1) {
                        return {
                            roles: [Admin],
                            title:
                                "كشف حساب- الأساتذة-(" +
                                new Date().toISOString().substr(0, 10) +
                                ")"
                        };
                    } else {
                        return {
                            roles: [Admin],
                            title:
                                "كشف حساب-(" +
                                new Date().toISOString().substr(0, 10) +
                                ")"
                        };
                    }
                },
                component: () =>
                    import(
                        "@/views/finance-earnings/invoice/components/invoice-print.vue"
                    )
            }
        ]
    }
];
