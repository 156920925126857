import { Admin } from "../index"

export default [
    {
        path: '/user',
        component: () => import('@/views/user'),
        children: [
            {
                path: '',
                name: 'user default',
                redirect: '/user/1'
            },
            {
                path: ':type/',
                name: "users",
                props: true,
                meta: (route) => {
                    if (route.params && route.params.type == 1) {
                        return {
                            pageTitle: 'مستخدمو اللوحة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'مستخدمو اللوحة',
                                    active: true,
                                },
                            ]
                        }
                    } else if (route.params && route.params.type == 2) {
                        return {
                            pageTitle: 'الأساتذة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الأساتذة',
                                    active: true,
                                },
                            ]
                        }
                    } else if (route.params && route.params.type == 3) {
                        return {
                            pageTitle: 'مجيبو الأسئلة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'مجيبو الأسئلة',
                                    active: true,
                                },
                            ]
                        }
                    } else if (route.params && route.params.type == 4) {
                        return {
                            pageTitle: 'نقاط البيع',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'نقاط البيع',
                                    active: true,
                                },
                            ]
                        }
                    } else if (route.params && route.params.type == 5) {
                        return {
                            pageTitle: 'الطلاب',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الطلاب',
                                    active: true,
                                },
                            ]
                        }
                    } else{
                        return {
                            pageTitle: 'الحسابات',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الحسابات',
                                    active: true,
                                },
                            ]
                        }
                    }
                },
                component: () => import('@/views/user/components/user-table.vue'),
            },
            {
                path: ':type/create',
                name: 'user create',
                props: true,
                component: () => import('@/views/user/components/user-create.vue'),
                meta: () => ({
                    pageTitle : "إضافة حساب",
                    roles: [Admin],
                })
            },
            {
                path: ':type/:userId/details',
                name: 'user details',
                props: true,
                meta: (route) => {
                    if (route.params && route.params.type == 1 ) {
                        return {
                            pageTitle: 'مستخدمو اللوحة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'مستخدمو اللوحة',
                                    to: '/user/1',
                                    active: false,
                                },
                                {
                                    icon: 'pen',
                                    text: 'تفاصيل',
                                    active: true,
                                  },
                            ]
                        }
                    } else if (route.params && route.params.type == 2) {
                        return {
                            pageTitle: 'الأساتذة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الأساتذة',
                                    to: '/user/2',
                                    active: false,
                                },
                                {
                                    icon: 'pen',
                                    text: 'تفاصيل',
                                    active: true,
                                  },
                            ]
                        }
                    } else if (route.params && route.params.type == 3) {
                        return {
                            pageTitle: 'مجيبو الأسئلة',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'مجيبو الأسئلة',
                                    to: '/user/3',
                                    active: false,
                                },
                                {
                                    icon: 'pen',
                                    text: 'تفاصيل',
                                    active: true,
                                },
                            ]
                        }
                    } else if (route.params && route.params.type == 4) {
                        return {
                            pageTitle: 'نقاط البيع',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'نقاط البيع',
                                    to: '/user/4',
                                    active: false,
                                },
                                {
                                    icon: 'pen',
                                    text: 'تفاصيل',
                                    active: true,
                                  },
                            ]
                        }
                    } else if (route.params && route.params.type == 5) {
                        return {
                            pageTitle: 'الطلاب',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الطلاب',
                                    to: '/user/5',
                                    active: false,
                                },
                                {
                                    icon: 'pen',
                                    text: 'تفاصيل',
                                    active: true,
                                  },
                            ]
                        }
                    }
                    else{
                        return {
                            pageTitle: 'الحسابات',
                            roles: [Admin],
                            breadcrumb: [
                                {
                                    text: 'الحسابات',
                                    active: false,
                                }
                            ]
                        }
                    }
                },
                component: () => import('@/views/user/components/user-details.vue'),
            },
        ]
      },
  ]
  