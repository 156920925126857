import axiosIns from "@axios"
import store from "@/store"
import router from "@/router"
import Swal from "sweetalert2";
import { base64StringToBlob } from 'blob-util';
import {
    getUserData
} from "@/auth/utils";

export default {
    state: {
        isCreateDialog: false,
        lessons: [],
        mainLissonList: [],
        currentLessonList: [],
        lessonsActivePage: [],
        questionsActivePage: [],
        pageLength: 12,
        questionsPageLength: 4,
        lessonsFirstPage: 1,
        questionsFirstPage: 1,
        filterdQuestions: [],
        questionBankList: [],
        detailsVideoSelectedIndex: -1,
        lessonDto: {
            id: 0,
            name: '',
            lessonOrder: 0,
            videosCount: 0,
            studentsQuestionsCount: 0,
            subscriptionsCount: 0,
            hasUnAnsweredQuestions: false,
            unitId: null,
            unitName: '',
            lessonVideos: []
        },
        videoDto: {
            id: 0,
            name: '',
            description: '',
            videoPath: '',
            fileDto: {},
            thumbPath: '',
            thumbDto: {},
            videoOrder: 0,
            lessonId: 0,
            lessonName: '',
            unitName: '',
            subjectName: '',
            teacherName: '',
            fileType: 0,
            questionBankIds: [],
            fileBase64: "",
        },
        questionDto: {
            id: 0,
            videoId: 0,
            lessonName: '',
            question: '',
            dateAsked: new Date(),
            studentName: '',
            answer: null,
            answerType: 0,
            datePaid: null,
            dateAnswered: new Date(),
            responderId: null
        }
    },
    getters: {
        isCreateDialog(state) {
            return state.isCreateDialog
        },
        lessons(state) {
            return state.lessons
        },
        lessonDto(state) {
            return state.lessonDto
        },
        lessonsActivePage(state) {
            return state.lessonsActivePage
        },
        questionsActivePage(state) {
            return state.questionsActivePage
        },
        pageLength(state) {
            return state.pageLength
        },
        questionsPageLength(state) {
            return state.questionsPageLength
        },
        lessonsFirstPage(state) {
            return state.lessonsFirstPage
        },
        questionsFirstPage(state) {
            return state.questionsFirstPage
        },
        detailsVideoSelectedIndex(state) {
            return state.detailsVideoSelectedIndex
        },
        filterdQuestions(state) {
            return state.filterdQuestions
        },
        questionDto(state) {
            return state.questionDto
        },
        videoDto(state) {
            return state.videoDto
        },
        questionBankList(state) {
            return state.questionBankList;
        },
        mainLissonList(state) {
            return state.mainLissonList
        },
        currentLessonList(state) {
            return state.currentLessonList
        },
        fileBase64(state) {
            return state.fileBase64
        }
    },
    mutations: {
        OPEN_CREATE_DIALOG(state, is) {
            Object.assign(state.lessonDto, {
                    id: 0,
                    name: '',
                    lessonOrder: 0,
                    videosCount: 0,
                    studentsQuestionsCount: 0,
                    subscriptionsCount: 0,
                    // unitId: null,
                    unitName: '',
                    lessonVideos: []
                }),
                state.isCreateDialog = is
        },
        GET_LESSONS(state, payload) {
            state.lessons = payload.data
            state.mainLissonList = [...payload.data]
            store.dispatch("fetchFilteredLessons", payload.unitType)
            //  store.dispatch("updateLessonPagination", 1)
        },
        CREATE_LESSONS(state, lesson) {
            console.log(lesson)
            state.lessons.unshift(lesson)
            //state.mainLissonList.unshift(lesson)

            store.dispatch("toggleCreateLessonDialog")
            router.push(`/lessons/${lesson.unitType}`)
            store.dispatch("fetchFilteredLessons", lesson.unitType)
            state.lessonDto.unitId = null;
            //store.dispatch("updateLessonPagination", 1)
        },
        REMOVE_LESSON(state, lesson) {
            const index = state.lessons.findIndex(item => item.id == lesson.id);
            state.lessons.splice(index, 1);

            const indexMainLissonList = state.mainLissonList.findIndex(item => item.id == lesson.id);
            state.mainLissonList.splice(indexMainLissonList, 1);

            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الدرس بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            store.dispatch("updateLessonPagination", 1);
        },
        GET_LESSON_DETAILS(state, lesson) {
            if (!lesson.lessonDto.fileDto) {
                lesson.lessonDto.fileDto = {}
            }
            if (!lesson.lessonDto.thumbDto) {
                lesson.lessonDto.thumbDto = {}
            }
            Object.assign(state.lessonDto, lesson.lessonDto)
            store.commit('UNITS_LIST', lesson.unitsList)
        },
        GET_VIDEO_DETAILS(state, payload) {
            console.log(payload.videoDto)
            state.videoDto.fileBase64 = '';
            if (!payload.videoDto) {
                Object.assign(state.videoDto, {
                    id: 0,
                    name: '',
                    description: '',
                    videoPath: '',
                    fileDto: {},
                    thumbPath: '',
                    thumbDto: {},
                    videoOrder: 0,
                    lessonId: 0,
                    lessonName: '',
                    unitName: '',
                    subjectName: '',
                    teacherName: '',
                    questionBankIds: [],
                    fileBase64: '',
                })
                state.filterdQuestions = []
                state.detailsVideoSelectedIndex = -1
                store.dispatch("updateVideoQuestionsPagination", 1)
            } else {
                const setVideoInterval = setInterval(() => {
                    if (state.lessonDto.lessonVideos.length) {
                        clearInterval(setVideoInterval)
                        state.lessonDto.lessonVideos.map((videoItem, index) => { // to get index
                            if (videoItem.id == payload.videoDto.id) {
                                Object.assign(state.videoDto, payload.videoDto);
                                state.filterdQuestions = payload.videoDto.videoQuestions
                                state.detailsVideoSelectedIndex = index
                                store.dispatch("updateVideoQuestionsPagination", 1)
                            }
                        })
                    }
                }, 200)
            }
            state.questionBankList = payload.questionBankList
        },
        GET_QUESTION(state, question) {
            question.dateAnswered = !question.dateAnswered ? null : new Date(question.dateAnswered).toISOString().substr(0, 19).replace('T', ' ')
            question.dateAsked = new Date(question.dateAsked).toISOString().substr(0, 19).replace('T', ' ')
            Object.assign(state.questionDto, question)
        },
        SUBMIT_VIDEO(state, videoDto) {
            console.log(videoDto)
            state.lessonDto.lessonVideos.unshift(videoDto)
            Object.assign(state.videoDto, {
                id: 0,
                name: '',
                description: '',
                videoPath: '',
                fileDto: {},
                thumbPath: '',
                thumbDto: {},
                videoOrder: 0,
                lessonId: 0,
                lessonName: '',
                unitName: '',
                subjectName: '',
                teacherName: '',
                questionBankIds: [],
            })
        },
        SUBMIT_VIDEO_UPDATE(state, videoDto) {
            state.lessonDto.lessonVideos.map((video) => {
                if (video.id == videoDto.id) {
                    Object.assign(video, videoDto);
                }
            });
            Object.assign(state.videoDto, videoDto);
        },
        DELETE_VIDEO(state, payload) {
            if (payload.data) {
                const index = state.lessonDto.lessonVideos.findIndex(video => video.id == payload.id)
                state.lessonDto.lessonVideos.splice(index, 1)
                router.push('./0')
                Swal.fire({
                    title: "تم الحذف !",
                    text: `تم حذف الفيديو بنجاح .`,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {
                Swal.fire({
                    title: "تم الحذف !",
                    text: 'فشل في حذف الفيديو.',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        },
        UPDATE_FILTERD_VIDEO_QUESTIONS_LIST(state, isAnswerd) {
            console.log(state.videoDto)
            state.filterdQuestions = state.videoDto.videoQuestions
                .filter((question) => !!question.responderId || isAnswerd)
            store.dispatch("updateVideoQuestionsPagination", 1)
        },
        UPDATE_LESSONS_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.pageLength,
                    items: state.mainLissonList
                })
                .then(data => {
                    state.lessonsActivePage = data
                    state.lessonsFirstPage = currentPage
                })
        },
        UPDATE_VIDEO_QUESTIONS_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.questionsPageLength,
                    items: state.filterdQuestions
                })
                .then(data => {
                    state.questionsActivePage = data
                    state.questionsFirstPage = currentPage
                })
        },
        ANSWER_QUESTION() {
            Swal.fire({
                title: "تم الإجابة !",
                icon: "success",
                showConfirmButton: false,
                timer: 1500
            });
        }
    },
    actions: {
        toggleCreateLessonDialog(context, is) {
            context.commit("OPEN_CREATE_DIALOG", is)
        },
        updateLessonPagination(context, currentPage) {
            context.commit("UPDATE_LESSONS_PAGINATION", currentPage)
        },
        updateVideoQuestionsPagination(context, currentPage) {
            context.commit("UPDATE_VIDEO_QUESTIONS_PAGINATION", currentPage)
        },
        filterQuestions(context, isAnswerd) {
            context.commit('UPDATE_FILTERD_VIDEO_QUESTIONS_LIST', isAnswerd)
        },
        async getLessons(context, unitType) {
            // let teacherId = 0;
            // const token = getUserData();
            // if (token.role !== "Admin") {
            //     teacherId = token.id;
            // }
            await axiosIns
                .get(`Lesson/GetLessons`)
                .then(data => {
                    context.commit("GET_LESSONS", {
                        data: data.data,
                        unitType: unitType
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async createLesson(context, payload) {
            await axiosIns
                .post("Lesson/SetLesson", {
                    id: payload.id,
                    name: payload.name,
                    unitId: payload.unitId,
                    lessonOrder: +payload.lessonOrder
                })
                .then(data => {
                    context.commit("CREATE_LESSONS", data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async removeLesson(context, payload) {
            await axiosIns.delete(`Lesson/RemoveLesson?lessonId=${payload.id}`);
            context.commit('REMOVE_LESSON', payload);
        },
        async getLessonsById(context, payload) {
            await axiosIns
                .get(`Lesson/GetLessonDetails?lessonId=${payload}`)
                .then(data => {
                    context.commit('GET_LESSON_DETAILS', data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getVideoDetils(context, id) {
            axiosIns.get(`Video/GetVideoDetails?videoId=${id}`)
                .then((data) => {
                    console.log(data.data)
                    context.commit('GET_VIDEO_DETAILS', data.data)
                }).catch((error) => {
                    console.log(error)
                })

        },
        async getVideoQuestionDetails(context, id) {
            axiosIns.get(`Video/GetVideoQuestionDetails?videoQuestionId=${id}`)
                .then((data) => {
                    context.commit('GET_QUESTION', data.data)
                }).catch((error) => {
                    console.log(error)
                })
        },
        async downloadFile(context, payload) {
            console.log(payload)
            axiosIns.post(`Video/DownloadFile`, {
                    path: payload
                })
                .then(data => {
                    const blob = base64StringToBlob(data.data,'application/pdf')
                    const blobUrl = URL.createObjectURL(blob);
                    let a = document.createElement('a')
                    a.setAttribute('href',blobUrl);
                    a.setAttribute('target', '_blank');
                    document.body.appendChild(a)
                    a.click();
                })


        },
        async submitVideo(context, dataFile) {
            store.dispatch('app/shownCloseRequest', true)
            dataFile.lessonId = +router.currentRoute.params.id
            dataFile.videoOrder = +dataFile.videoOrder
            const videoDto = new FormData();
            videoDto.append("file", dataFile.file);
            videoDto.append("lessonId", dataFile.lessonId);
            videoDto.append("videoOrder", dataFile.videoOrder);
            videoDto.append("id", dataFile.id);
            videoDto.append("name", dataFile.name);
            videoDto.append("fileType", dataFile.fileType)
            console.log(dataFile.description)
            if (!dataFile.description) {
                console.log();
            } else {
                videoDto.append("description", dataFile.description);

            }
            videoDto.append("thumbDto", dataFile.thumbDto);
            videoDto.append("videoPath", dataFile.videoPath);
            dataFile.questionBankIds
                .forEach((item) => videoDto.append("questionBankIds[]", item));

            axiosIns.post('Video/SetVideo', videoDto)
                .then((data) => {
                    if (!dataFile.id) {
                        context.commit('SUBMIT_VIDEO', data.data)
                    } else {
                        context.commit('SUBMIT_VIDEO_UPDATE', data.data)
                    }
                }).catch((error) => {
                    console.log(error)
                })
        },
        async postQuestionAnswer(context, answer) {
            answer.dateAnswered = new Date(answer.dateAnswered);
            answer.dateAsked = new Date(answer.dateAsked);
            answer.answerType = +answer.answerType;
            answer.responderId = getUserData().id;
            console.log(answer);
            axiosIns.post('Video/AnswerVideoQuestion', answer)
                .then((data) => {
                    context.commit('ANSWER_QUESTION', data.data)
                }).catch((error) => {
                    console.log(error)
                })
        },
        async deleteVideo({
            commit
        }, id) {
            const {
                data
            } = await axiosIns.delete('Video/RemoveVideo?videoId=' + id)
            commit('DELETE_VIDEO', {
                data,
                id
            })
        },
        async filterLessons({
            state
        }) {
            state.mainLissonList = await store.dispatch('app/filterItems', {
                items: 'currentLessonList',
                key: 'name'
            })
            store.dispatch("updateLessonPagination", 1);
        },
        fetchFilteredLessons({
            state
        }, index) {
            console.log(state.lessons)
            state.mainLissonList = state.lessons.filter(item => {
                return item.unitType == index;
            })
            state.currentLessonList = state.mainLissonList;
            store.dispatch("updateLessonPagination", 1);
        },
        fillUnitId({
            state
        }, id) {
            state.lessonDto.unitId = id;
            console.log(state.lessonDto.unitId)
        },
        resetUnitId({
            state
        }) {
            state.lessonDto.unitId = null;
            console.log(state.lessonDto.unitId)
        }
    }
}