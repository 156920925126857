import store from "@/store";
import axiosIns from "@/libs/axios";

export default {
    state: {
        activeTableQuestions: [],
        responderQuestionsPageLength: 10,
        responderQuestionsFirstPage: 1,
        answeardQuestion: [],
        unAnsweardQuestion: []
    },
    getters: {
        activeTableQuestions(state) {
            return state.activeTableQuestions
        },
        responderQuestionsPageLength(state) {
            return state.responderQuestionsPageLength
        },
        responderQuestionsFirstPage(state) {
            return state.responderQuestionsFirstPage
        },
        answeardQuestion(state) {
            return state.answeardQuestion
        },
        unAnsweardQuestion(state) {
            return state.unAnsweardQuestion
        }
    },
    mutations: {
        GET_RESPONDER_QUESTION(state, {data, type}) {
            console.log(data)
            state.answeardQuestion = data.qusetionsAnswered
            state.unAnsweardQuestion = data.questionsNotAnswered
            store.dispatch("updateQuestionsPagination", {currentPage: 1, type})
        },
        UPDATE_QUESTIONS_PAGINATION(state, {currentPage, type}) {
            if(type) {
                store
                    .dispatch("app/pageChanged", {
                        currentPage: currentPage,
                        pageLength: state.responderQuestionsPageLength,
                        items: state.answeardQuestion
                    })
                    .then(data => {
                        state.activeTableQuestions = data
                        state.responderQuestionsFirstPage = currentPage
                    })
            } else {
                store
                    .dispatch("app/pageChanged", {
                        currentPage: currentPage,
                        pageLength: state.responderQuestionsPageLength,
                        items: state.unAnsweardQuestion
                    })
                    .then(data => {
                        state.activeTableQuestions = data
                        state.responderQuestionsFirstPage = currentPage
                    })
            }
        },
    },
    actions: {
        updateQuestionsPagination(context, payload) {
            context.commit("UPDATE_QUESTIONS_PAGINATION", payload)
        },
        getQuestions({ commit }, type) {
            axiosIns.get('Video/GetVideoQuestions')
                .then((data) => {
                    commit('GET_RESPONDER_QUESTION', {data: data.data, type})
                }).catch((err) => {
                    console.log(err)
                })
        }
    }
  }