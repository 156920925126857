import axiosIns from "@axios";
import store from "@/store";
import Swal from "sweetalert2";
//import router from '@/router';

export default {
    state: {
        ads: [],
        mainAdsList: [],
        id: 0,
        isAdDialog: false,
        adPageLength: 8,
        adsActivePage: [],
        adsFirstPage: 1,
        adForm: {
            id: 0,
            title: "",
            startDate: new Date(),
            endDate: "",
            imagePath: "",
            imageDto: {}
        }
    },
    getters: {
        isAdDialog(state) {
            return state.isAdDialog;
        },
        ads(state) {
            return state.ads;
        },
        adForm(state) {
            return state.adForm;
        },
        id(state) {
            return state.id;
        },
        adPageLength(state) {
            return state.adPageLength;
        },
        adsActivePage(state) {
            return state.adsActivePage;
        },
        adsFirstPage(state) {
            return state.adsFirstPage;
        },
        mainAdsList(state) {
            return state.mainAdsList;
        }
    },
    mutations: {
        OPEN_AD_DIALOG(state, payload) {
            console.log(payload)
            state.isAdDialog = payload;
            if (!payload) {
                state.id = 0;
            }
        },
        CLEAR_AD_FORM(state) {
            state.adForm = {
                id: 0,
                title: "",
                startDate: new Date(),
                endDate: null,
                imagePath: "",
                imageDto: {}
            };
        },
        SET_ID(state, id) {
            state.id = id;
        },
        GET_ADS_ITEMS(state, payload) {
            console.log(payload)
            state.ads = payload;
            state.mainAdsList = [...payload];
            store.dispatch("adsPagination", 1);
        },
        GET_AD_FORM(state, payload) {
            const idInterval = setInterval(() => {
                const index = state.ads.findIndex(item => item.id == payload);
                if(!state.ads[index].imageDto) {
                    state.ads[index].imageDto = {}
                }
                Object.assign(state.adForm, state.ads[index]);
                clearInterval(idInterval);
            }, 500);
        },
        ADD_AD_ITEM(state, payload) {
            console.log(payload)
            state.ads.unshift(payload);
            state.mainAdsList.unshift(payload);
            store.dispatch("adsPagination", 1);
        },
        UPDATE_AD_ITEM(state, payload) {
            console.log(payload)
            const index = state.ads.findIndex(item => item.id == payload.id);
            Object.assign(state.ads[index], payload);

            const indexMainAdsList = state.mainAdsList.findIndex(
                item => item.id == payload.id
            );
            Object.assign(state.mainAdsList[indexMainAdsList], payload);

            store.dispatch("adsPagination", 1);
        },
        REMOVE_AD(state, payload) {
            const index = state.ads.findIndex(item => item.id == payload);
            state.ads.splice(index, 1);

            const indexMainAdsList = state.mainAdsList.findIndex(
                item => item.id == payload
            );
            state.mainAdsList.splice(indexMainAdsList, 1);

            Swal.fire({
                title: "تم الحذف  !",
                text: `تم حذف الإعلان بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            store.dispatch("adsPagination", 1);
        },
        ADS_PAGINATION(state, currentPage) {
            store
                .dispatch("app/pageChanged", {
                    currentPage: currentPage,
                    pageLength: state.adPageLength,
                    items: state.mainAdsList
                })
                .then(data => {
                    state.adsActivePage = data;
                    state.adsFirstPage = currentPage;
                });
        }
    },
    actions: {
        toggleAdDialog(context, payload) {
            context.commit("OPEN_AD_DIALOG", payload);
        },
        adsPagination(context, currentPage) {
            context.commit("ADS_PAGINATION", currentPage);
        },
        setId(context, id) {
            context.commit("SET_ID", id);
        },
        async fetchAds(context) {
            const { data } = await axiosIns.get("/Ad/GetAllAds");
            context.commit("GET_ADS_ITEMS", data);
        },
        fetchAdForm(context, payload) {
            context.commit("GET_AD_FORM", payload);
        },
        async setAd(context, payload) {
            const { data } = await axiosIns.post("/Ad/SetAd", {
                Id: payload.id,
                Title: payload.title,
                StartDate: new Date(payload.startDate),
                EndDate: new Date(payload.endDate),
                ImageDto: payload.imageDto,
                ImagePath: payload.imagePath
            });
            if (payload.id == 0) {
                context.commit("ADD_AD_ITEM", data);
            } else {
                context.commit("UPDATE_AD_ITEM", data);
            }
        },
        async removeAd(context, payload) {
            await axiosIns.delete(`/Ad/DeleteAd?id=${payload}`);
            context.commit("REMOVE_AD", payload);
        },
        clearAdForm(context) {
            context.commit("CLEAR_AD_FORM");
        },
        async filterAds({ state }) {
            state.mainAdsList = await store.dispatch("app/filterItems", {
                items: "ads",
                key: "title"
            });
            store.dispatch("adsPagination", 1);
        }
    }
};
