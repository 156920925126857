import { Admin } from "../index"
export default [
  {
    path: '/rates',
    name: 'rates',
    component: () => import('@/views/settings/rates'),
    meta: () => ({
      pageTitle: 'النسب',
      roles: [Admin],
      breadcrumb: [
        {
          icon: 'moneybag',
          text: 'النسب',
          active: true,
        },
      ],
    }),
  },
]
