import axios from '@/libs/axios';

export default {
    state: {
        settingsForm:{
            posRate: 0,
            teacherRate: 0,
            responderAnsValue: 0
        }
    },
    getters: {
        settingsForm(state)
        {
            return state.settingsForm;
        }
    },
    mutations: {
        GET_SETTINGS(state, payload) {
            Object.assign(state.settingsForm, payload);
        },
        UPDATE_SETTINGS(state, payload) {
            Object.assign(state.settingsForm, payload);
        }
    },
    actions: {
        async fetchSettings(context) {
            const { data } = await axios.get('/Settings/GetSettings');
            context.commit('GET_SETTINGS', data);
        //     const option = {
        //         method: 'GET',
        //         url: 'https://moul-scaleway-v1.p.rapidapi.com/tokens',
        //         headers: {
        //           'x-auth-token': 'undefined',
        //           'x-rapidapi-key': 'SIGN-UP-FOR-KEY',
        //           'x-rapidapi-host': 'moul-scaleway-v1.p.rapidapi.com'
        //     }
        // };
        //     axios.request(option)
        },
        async setSettings(context,payload) {
            const { data } = await axios.post('/Settings/SetSettings',{
                PosRate: +payload.posRate,
                TeacherRate: +payload.teacherRate,
                ResponderAnsValue: +payload.responderAnsValue
            });
            context.commit('UPDATE_SETTINGS', data);
        },
        async setPic( payload) {
            console.log(payload);
            const { data } = await axios.post("https://lms.s3.nl-ams.scw.cloud/", payload, {
                headers: {
                    'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
                    'X-Amz-Credential': 'SCWRWTHB78S8D75PSX32%2F20210214%2Fnl-ams%2Fs3%2Faws4_request',
                    'X-Amz-Date': new Date(),
                    'X-Amz-SignedHeaders': 'host'
                },
            });
            console.log(data);
        }
    },
  }