import axiosIns from "@axios"
export default{
    state: {
        statistics : {
            subjectCount : 0,
            unitCount : 0,
            lessonCount : 0,
            videoCount : 0
        },
        orderChart: {
            adCount : 0,
            adMonthly : []
        },
        earningChartPaid : {
            title : 'الأكواد المباعة',
            total : 0,
            compareRate : 0,
            codeCount : 0,
            allCodeCount : 0,
        },
        earningChartWorthly :{
            title : 'الأكواد المستحقة',
            total : 0,
            compareRate : 0,
            codeCount : 0,
            allCodeCount : 0,
        },
        revenueReport : {
            totalEarning : 0,
            importsMonthly : [],
            exportsMonthly : []
        },

        profitChartQusAns : {
            questionCount : 0,
            answerCount : 0,
            answeredRateMonthly : []
        },

        teachersCount : 0,
        studentCount : 0,
        respondersCount : 0,
        posCount : 0,
        teacherMonthly : [],
        responderMonthly : [],
        posMonthly : [],
        studentMonthly :[]
    },
    getters: {
        statistics(state){
            return state.statistics
        },
        orderChart(state){
            return state.orderChart
        },
        earningChartPaid(state){
            return state.earningChartPaid
        },
        earningChartWorthly(state){
            return state.earningChartWorthly;
        },

        teachersCount(state){
            return state.teachersCount
        },
        studentCount(state){
            return state.studentCount
        },
        respondersCount(state){
            return state.respondersCount
        },
        posCount(state){
            return state.posCount
        },

        teacherMonthly(state){
            return state.teacherMonthly
        },
        responderMonthly(state){
            return state.responderMonthly
        },
        posMonthly(state){
            return state.posMonthly
        },
        studentMonthly(state){
            return state.studentMonthly
        },

        revenueReport(state){
            return state.revenueReport
        },
        profitChartQusAns(state){
            return state.profitChartQusAns
        }

    },
    mutations: {
        GET_STATISTICS(state , payload){
            console.log(payload);
            //chart 1
            state.statistics.subjectCount = payload.subjectCount;
            state.statistics.unitCount = payload.unitCount;
            state.statistics.lessonCount = payload.lessonCount;
            state.statistics.videoCount = payload.videoCount;
            
            //chart 2
            state.orderChart.adCount = payload.adCount;
            state.orderChart.adMonthly = payload.adMonthly;
            
            state.profitChartQusAns.questionCount = payload.questionCount;
            state.profitChartQusAns.answerCount = payload.answerCount;
            state.profitChartQusAns.answeredRateMonthly = payload.answeredRateMonthly;


            //chart 3
            state.revenueReport.totalEarning = payload.totalEarning;
            state.revenueReport.exportsMonthly = payload.exportsMonthly;
            state.revenueReport.importsMonthly = payload.importsMonthly;

            
            // chart 4
            state.earningChartPaid.total = payload.totalPaid;
            state.earningChartPaid.compareRate = payload.compareRatePaid;
            state.earningChartPaid.allCodeCount = payload.codeCount;
            state.earningChartPaid.codeCount = payload.codePaid;

             //chart 5
            state.earningChartWorthly.total = payload.totalWorthly;
            state.earningChartWorthly.compareRate = payload.compareRateWorthly;
            state.earningChartWorthly.allCodeCount = payload.codeCount;
            state.earningChartWorthly.codeCount = payload.codeWorthly;

            //chart 6
            state.teachersCount = payload.teachersCount;
            state.studentCount = payload.studentCount;
            state.respondersCount = payload.respondersCount;
            state.posCount = payload.posCount;

            state.teacherMonthly = payload.teacherMonthly;
            state.responderMonthly = payload.responderMonthly;
            state.posMonthly = payload.posMonthly;
            state.studentMonthly = payload.studentMonthly;

            
        }
    },
    actions: {
        async fetchHome(context) {
            const { data } = await axiosIns.get('/Home/GetStatistics');
            context.commit("GET_STATISTICS" , data)
        }
    }
}