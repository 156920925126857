import { All, Responder } from "../index"

export default [
    {
        path: "/received-questions",
        name: "received questions",
        component: () => import("@/views/responder/received-questions"),
        meta: () => ({
            pageTitle: "الأسئلة الواردة",
            roles: [All, Responder],
            breadcrumb: [
                {
                    text: "الأسئلة الواردة",
                    active: true
                }
            ]
        })
    },
    {
        path: "/answers-record",
        name: "answers record",
        component: () => import("@/views/responder/answers-record"),
        meta: () => ({
            pageTitle: "سجل الإجابات",
            roles: [All, Responder],
            breadcrumb: [
                {
                    text: "سجل الإجابات",
                    active: true
                }
            ]
        })
    }
];
