import { Admin, Teacher, Responder } from "../index"

export default [
    {
        path: '/subjects-units',
        name: 'subjects units',
        component: () => import('@/views/content-management/subjects-units'),
        redirect: '/subjects-units/subjects',
        children: [
            {
                path: 'subjects',
                name: 'subjects',
                component: () => import('@/views/content-management/subjects-units/components/subjects.vue'),
                meta: () => ({
                    pageTitle: 'المواد الدراسية',
                    roles: [Admin],
                    breadcrumb: [
                        {
                            icon: 'books',
                            text: 'المواد الدراسية',
                            active: true,
                        },
                    ],
                })
            },
            {
                path: 'units',
                name: 'units',
                component: () => import('@/views/content-management/subjects-units/components/unitsTable.vue'),
                meta: () => ({
                    roles: [Admin, Teacher],
                    pageTitle: 'الوحدات الدراسية',
                    breadcrumb: [
                        {
                            icon: 'books',
                            text: 'الوحدات الدراسية',
                            active: true,
                        },
                    ],
                }),
            },
        ],
    },
    {
        path: "/subjects-units/subjects/details/:id",
        name: "subject details",
        meta: () => ({
            pageTitle: 'تفاصيل المادة',
            roles: [Admin],
            breadcrumb: [
                {
                    icon: 'books',
                    text: 'المواد الدراسية',
                    to: '/subjects-units/subjects',
                    active: false,
                },
                {
                    icon: 'pen',
                    text: 'تفاصيل المادة',
                    active: true,
                }]
        }),
        props: true,
        component: () => import("@/views/content-management/subjects-units/components/subjectDetails.vue")
    },
    {
        path: "/subjects-units/units/details/:id",
        name: "unit details",
        meta: () => ({
            pageTitle: 'تفاصيل الوحدة',
            roles: [Admin, Teacher],
            breadcrumb: [
                {
                    icon: 'books',
                    text: 'الوحدات الدراسية',
                    to: '/subjects-units/units',
                    active: false,
                },
                {
                    icon: 'pen',
                    text: 'تفاصيل الوحدة',
                    active: true,
                }]
        }),
        props: true,
        component: () => import("@/views/content-management/subjects-units/components/unitDetails.vue")
    },
    {
        path: '/lessons',
        name : 'lessons',
        props : true,
        component: () => import('@/views/content-management/lessons'),
        redirect : 'lessons/0',
        children: [
            {
                path: ':index',
                name: 'lessons units',
                props : true,
                component: () => import('@/views/content-management/lessons/components/lessons.vue'),
                meta: (route) => {
                    if(route.params && +route.params.index == 0){
                        return {
                            pageTitle: 'الدروس',
                            roles: [Admin, Teacher],
                            breadcrumb: [
                                {
                                    icon: 'bookmark',
                                    text: 'الدروس - دروس الوحدات ',
                                    active: true,
                                },
                            ],
                        }
                    }
                    else{
                        return {
                            pageTitle: 'الدروس',
                            roles: [Admin, Teacher],
                            breadcrumb: [
                                {
                                    icon: 'bookmark',
                                    text: 'الدروس - الجلسات الامتحانية',
                                    active: true,
                                },
                            ],
                        } 
                    }
                    
                }
            },
        ]
    },
    {
        path: '/lessons/:id/:videoId',
        component: () => import('@/views/content-management/lessons/components/lesson-details.vue'),
        props: true,
        children: [
            {
                path: '',
                name: 'lessons details',
                meta: () => ({
                    pageTitle: 'الدروس',
                    roles: [Admin, Teacher],
                    breadcrumb: [
                        {
                            icon: 'bookmark',
                            text: 'الدروس',
                            to: '/lessons',
                            active: false,
                        },
                        {
                            icon: 'pen',
                            text: 'تفاصيل',
                            active: true,
                        },
                    ],
                }),
                props: {
                    'video-details': true,
                    'video-quesstions': true,
                },
                components: {
                    'video-details': () => import('@/views/content-management/lessons/components/video-details/info.vue'),
                    'video-quesstions': () => import('@/views/content-management/lessons/components/video-details/questions.vue'),
                }
            }
        ]
    },
    {
        path: '/lessons/:id/:videoId/question/:quesId',
        name: 'answerd question details',
        props: true,
        meta: (route) => ({
            pageTitle: 'الأسئلة',
            roles: [Admin, Teacher, Responder],
            breadcrumb: [
                {
                    icon: 'bookmark',
                    text: 'الدروس',
                    to: '/lessons',
                    active: false,
                },
                {
                    icon: 'pen',
                    text: 'تفاصيل درس',
                    to: '/lessons/' + (!route.params ? 0 : route.params.id) + '/' + (!route.params ? 0 : route.params.videoId),
                    active: false,
                },
                {
                    icon: 'question-circle',
                    text: 'تفاصيل السؤال',
                    active: true,
                },
            ],
        }),
        component: () => import('@/views/content-management/lessons/components/video-details/quesions-details/answerd-questions.vue')
    },
    {
        path: '/lessons/:id/:videoId/question/un/:quesId',
        name: 'unanswerd question details',
        props: true,
        meta: (route) => ({
            pageTitle: 'الأسئلة',
            roles: [Admin, Teacher, Responder],
            breadcrumb: [
                {
                    icon: 'bookmark',
                    text: 'الدروس',
                    to: '/lessons',
                    active: false,
                },
                {
                    icon: 'pen',
                    text: 'تفاصيل درس',
                    to: '/lessons/' + (!route.params ? 0 : route.params.id) + '/' + (!route.params ? 0 : route.params.videoId),
                    active: false,
                },
                {
                    icon: 'question-circle',
                    text: 'تفاصيل السؤال',
                    active: true,
                },
            ],
        }),
        component: () => import('@/views/content-management/lessons/components/video-details/quesions-details/un-answerd-questions.vue')
    },



    // {
    //     path: '/lessons/:id/:videoId/question',

    //     component: () => import('@/views/content-management/lessons'),
    //     children: [
    //         {
    //             path: '',
    //             redirect: '/error-404',
    //         },
            
            
    //     ]
    // },

    {
        path: '/question-bank',
        component: () => import('@/views/content-management/question-bank'),
        children: [
            {
                path: '',
                name: 'question bank',
                component: () => import('@/views/content-management/question-bank/question-bank-table.vue'),
                meta: () => ({
                    pageTitle: 'بنك الأسئلة',
                    roles: [Admin, Teacher],
                    breadcrumb: [
                        {
                            icon: 'file-question-alt',
                            text: 'بنك الأسئلة',
                            active: true,
                        },
                    ],
                }),
            },
            {
                path: '/question-bank/:id',
                name: 'question bank action',
                props: true,
                component: () => import('@/views/content-management/question-bank/action-questions-bank.vue'),
                meta: (route) => ({
                    pageTitle: 'بنك الأسئلة',
                    roles: [Admin, Teacher],
                    breadcrumb: [
                        {
                            icon: 'file-question-alt',
                            text: 'بنك الأسئلة',
                            to: '/question-bank',
                            active: false,
                        },
                        {
                            text: (route.params && !+route.params.id) ? 'إضافة' : 'تعديل',
                            active: true,
                        }
                    ]
                }),
            }
        ]
    },
    {
        path: '/exam-sessions',
        name: 'exam sessions',
        component: () => import('@/views/content-management/exam-sessions'),
        meta: () => ({
            pageTitle: 'الجلسات الإمتحانية',
            roles: [Admin,Teacher],
            breadcrumb: [
                {
                    icon: 'question-circle',
                    text: 'الجلسات الإمتحانية',
                    active: true,
                },
            ],
        }),
    },
    {
        path: '/exam-sessions/details/:id',
        name: 'session details',
        props: true,
        component: () => import('@/views/content-management/exam-sessions/components/session-details'),
        meta: () => ({
            pageTitle: 'تفاصيل الجلسة',
            roles: [Admin,Teacher],
            breadcrumb: [
                {
                    icon: 'question-circle',
                    text: 'الجلسات الإمتحانية',
                    to: '/exam-sessions',
                    active: false,
                },
                {
                    icon: 'pen',
                    text: 'تفاصيل الجلسة',
                    active: true,
                },
            ],
        }),
    },
    {
        path: '/ads',
        name: 'ads',
        component: () => import('@/views/content-management/ads'),
        meta: () => ({
            pageTitle: 'الإعلانات',
            roles: [Admin],
            breadcrumb: [
                {
                    icon: 'megaphone',
                    text: 'الإعلانات',
                    active: true,
                },
            ],
        })
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/views/content-management/notifications'),
        meta: () => ({
            pageTitle: 'الإشعارات',
            icon: 'BellIcon',
            roles: [Admin],
            breadcrumb: [
                {
                    icon: 'bell',
                    text: 'الإشعارات',
                    active: true,
                },
            ],
        }),
    },
]
