import axiosIns from '@axios';
import store from '@/store';
import Swal from "sweetalert2";
import router from "@/router";
export default {
    state: {
        isSubjectDialog: false,
        isUnitDialog: false,
        unitsActivePage: [],
        unitsFirstPage: 1,
        unitPageLength: 10,
        unitListPageLength: 6,
        unitsListActivePage: [],
        unitsListFirstPage: 1,
        lessonListPageLength: 6,
        lessonsListActivePage: [],
        lessonsListFirstPage: 1,
        subjectsActivePage: [],
        subjectsFirstPage: 1,
        subjectPageLength: 8,
        subjects: [],
        searchSubjectsList: [],
        units: [],
        searchUnitsList: [],
        unitsList: [],
        subjectsList: [],
        teachersList: [],
        subjectForm: {
            id: 0,
            name: "",
            imagePath: null,
            imageDto:{},
            subjectUnits: [],
            subscriptionsCount: 0,
            unitsCount: 0,
            lessonsCount: 0
        },
        unitForm: {
            id: 0,
            name: "",
            unitLessons:[],
            description: "",
            subjectId: null,
            teacherId: null,
            price: null,
            unitType: 0,
            unitOrder: null,
            isFree: false,
            subscriptionsCount: 0,
            lessonsCount: 0,
            videosCount: 0
        }

    },
    getters: {
        subjects(state) {
            return state.subjects;
        },
        subjectsList(state) {
            return state.subjectsList;
        },
        isSubjectDialog(state) {
            return state.isSubjectDialog;
        },
        subjectForm(state) {
            return state.subjectForm;
        },
        units(state) {
            return state.units;
        },
        unitsList(state) {
            return state.unitsList;
        },
        isUnitDialog(state) {
            return state.isUnitDialog;
        },
        unitForm(state) {
            return state.unitForm;
        },
        teachersList(state) {
            return state.teachersList;
        },
        subjectsActivePage(state)
        {
            return state.subjectsActivePage;
        },
        subjectsFirstPage(state)
        {
            return state.subjectsFirstPage;
        },
        subjectPageLength(state)
        {
            return state.subjectPageLength;
        },
        unitsActivePage(state)
        {
            return state.unitsActivePage;
        },
        unitsFirstPage(state)
        {
            return state.unitsFirstPage;
        },
        unitPageLength(state)
        {
            return state.unitPageLength;
        },
        unitsListActivePage(state)
        {
            return state.unitsListActivePage;
        },
        unitsListFirstPage(state)
        {
            return state.unitsListFirstPage;
        },
        unitListPageLength(state)
        {
            return state.unitListPageLength;
        },
        lessonsListActivePage(state)
        {
            return state.lessonsListActivePage;
        },
        lessonsListFirstPage(state)
        {
            return state.lessonsListFirstPage;
        },
        lessonListPageLength(state)
        {
            return state.lessonListPageLength;
        },
        searchUnitsList(state) {
            return state.searchUnitsList
        },
        searchSubjectsList(state) {
            return state.searchSubjectsList
        }
    },
    mutations: {
        OPEN_SUBJECT_DIALOG(state, payload) {
            state.isSubjectDialog = payload;
        },
        SUBJECTS_PAGINATION(state,currentPage)
        {
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.subjectPageLength,
                items: state.searchSubjectsList
            })
            .then(data => {
                state.subjectsActivePage = data;
                state.subjectsFirstPage = currentPage;
            })
        },
        GET_SUBJECTS_ITEMS(state, payload) {
            state.subjects = payload;
            state.searchSubjectsList = [...payload];
            store.dispatch("subjectsPagination", 1);
            state.subjectForm = {
                id: 0,
                name: "",
                imagePath: null,
                imageDto:{},
                subjectUnits: [],
                subscriptionsCount: 0,
                unitsCount: 0,
                lessonsCount: 0
            };
        },
        SUBJECTS_LIST(state, payload) {
            state.subjectsList = payload;
        },
        GET_SUBJECT_DETAILS(state, payload) {
            Object.assign(state.subjectForm, payload);
            store.dispatch("unitsListPagination", 1);
        },
        ADD_SUBJECT_ITEM(state, payload) {
            state.subjects.unshift(payload);
            state.searchSubjectsList.unshift(payload);
            state.subjectForm = {
                id: 0,
                name: "",
                imagePath: null,
                imageDto:{},
                subjectUnits: [],
                subscriptionsCount: 0,
                unitsCount: 0,
                lessonsCount: 0
            };
            store.dispatch("subjectsPagination", 1);
        },
        UPDATE_SUBJECT_ITEM() {
            // todo: why here dont upldate lists
            store.dispatch("subjectsPagination", 1);
        },
        REMOVE_SUBJECT(state, payload) {
            const subjectIndex = state.subjects.findIndex(item => item.id == payload.id);
            state.subjects.splice(subjectIndex, 1);
            
            const searchSubjectsListIndex = state.searchSubjectsList.findIndex(item => item.id == payload.id);
            state.searchSubjectsList.splice(searchSubjectsListIndex, 1);
            
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف المادة ${payload.name} بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            store.dispatch("subjectsPagination", 1);
        },
        
        GET_UNITS_ITEMS(state, payload) {
            state.units = payload;
            state.searchUnitsList = [...payload]
            store.dispatch("unitsPagination", 1);
            state.unitForm = {
                id: 0,
                name: "",
                description: "",
                unitLessons:[],
                subjectId: null,
                teacherId: null,
                price: null,
                unitType: 0,
                unitOrder: null,
                isFree: false,
                subscriptionsCount: 0,
                lessonsCount: 0,
                videosCount: 0
            }
        },
        UNITS_PAGINATION(state,currentPage)
        {
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.unitPageLength,
                items: state.searchUnitsList
            })
            .then(data => {
                state.unitsActivePage = data;
                state.unitsFirstPage = currentPage;
            })
        }, 
        UNITS_LIST_PAGINATION(state,currentPage)
        {
            
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.unitListPageLength,
                items: state.subjectForm.subjectUnits
            })
            .then(data => {
                state.unitsListActivePage = data;
                state.unitsListFirstPage = currentPage;
            })
        },
        LESSONS_LIST_PAGINATION(state,currentPage)
        {
            
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.lessonListPageLength,
                items: state.unitForm.unitLessons
            })
            .then(data => {
                state.lessonsListActivePage = data;
                state.lessonsListFirstPage = currentPage;
            })
        },
        UNITS_LIST(state, payload) {
            state.unitsList = payload;
        },
        OPEN_UNIT_DIALOG(state, payload) {
            state.isUnitDialog = payload;
        },
        GET_UNIT_DETAILS(state, payload)
        {
            Object.assign(state.unitForm,payload.unitDto);
            state.subjectsList = payload.subjectsList;
            state.teachersList = payload.teachersList;
            store.dispatch("lessonsListPagination", 1);
        },
        ADD_UNIT_ITEM(state, payload)
        {
            state.units.unshift(payload);
            state.searchUnitsList.unshift(payload);
            store.dispatch("unitsPagination", 1);
            state.unitForm = {
                id: 0,
                name: "",
                description: "",
                unitLessons:[],
                subjectId: null,
                teacherId: null,
                price: null,
                unitType: 0,
                unitOrder: null,
                isFree: false,
                subscriptionsCount: 0,
                lessonsCount: 0,
                videosCount: 0
            }
        },
        UPDATE_UNIT_ITEM()
        {
            // todo: why here dont upldate lists
            store.dispatch("unitsPagination", 1);
        },
        TEACHERS_LIST(state, payload) {
            state.teachersList = payload;
        },

        //remove unit

        REMOVE_UNIT(){
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الوحدة.`,
                icon: "success",
                confirmButtonText: "تم"
            });
            router.push(`/subjects-units/units`)
        },
        REMOVE_UNITS(state,payload)
        {
            let MapOfIds = new Map(); 
            var idx; 
            var tempList = []; 
            for(idx = 0 ; idx < payload.length ; idx++) {
                 MapOfIds.set(payload[idx] , 1);
            }
            for(idx = 0 ; idx < state.searchUnitsList.length ; idx++) {
                if(MapOfIds.has(state.searchUnitsList[idx].id) === false) {
                    tempList.push(state.searchUnitsList[idx]); 
                }
            }
            state.searchUnitsList = tempList; 
            state.unitsActivePage = state.searchUnitsList; 

            store.dispatch("unitsPagination", 1)
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الوحدات المحددة `,
                icon: "success",
                confirmButtonText: "تم"
            });
            
            // payload.forEach(id => {
            //     const index = state.units.findIndex(item => item.id == id);
            //     state.units.splice(index, 1);
                
            //     const searchUnitsListIndex = state.searchUnitsList.findIndex(item => item.id == id);
            //     state.searchUnitsList.splice(searchUnitsListIndex, 1);
            // });
            
            // Swal.fire({
            //     title: "تم الحذف !",
            //     text: `تم حذف الوحدات بنجاح .`,
            //     icon: "success",
            //     showConfirmButton: false,
            //     timer: 2000
            // });
            // store.dispatch("unitsPagination", 1);
        },
        CANT_REMOVE_UNKNOWN() {
            Swal.fire({
                title: "لا يمكن الحذف !",
                text: `حدث خطأ ما، الرجاء إعادة المحاولة.`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000
            });
        },
        CANT_REMOVE_KNOWN() {
            Swal.fire({
                title: "لا يمكن الحذف !",
                text: `لا يمكن حذف الوحدة لأنها تحوي على دروس.`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000
            });
        },
        CANT_MULTI_REMOVE_KNOWN() {
            Swal.fire({
                title: "تحذير !",
                icon: "warning",
                text: `لم يتم الحذف لارتباط بعض الوحدات بدروس.`,
                confirmButtonText: "تم"
            });
        },
        

    },
    actions: {
        subjectsPagination(context, currentPage) {
            context.commit("SUBJECTS_PAGINATION", currentPage)
        },
        async fetchSubjects(context) {
            const { data } = await axiosIns.get('/Subject/GetSubjects');

            context.commit('GET_SUBJECTS_ITEMS', data);
        },
        async getSubjectsList(context) {
            const { data } = await axiosIns.get('/Subject/SubjectsList');
            context.commit('SUBJECTS_LIST', data);
        },
        async fetchSubjectDetails(context, payload) {
            const { data } = await axiosIns.get(`/Subject/GetSubjectDetails?subjectId=${payload}`);
            context.commit('GET_SUBJECT_DETAILS', data);
        },
        toggleSubjectDialog(context, payload) {
            context.commit('OPEN_SUBJECT_DIALOG', payload)
        },
        async setSubject(context, payload) {
            const { data } = await axiosIns.post('/Subject/SetSubject', {
                Id: payload.id,
                Name: payload.name,
                imageDto: payload.imageDto,
            });
            if (payload.id == 0) {
                context.commit("ADD_SUBJECT_ITEM", data);
            } else {
                context.commit("UPDATE_SUBJECT_ITEM", data);
            }
        },
        async removeSubject(context, payload) {
            await axiosIns.delete(`/Subject/RemoveSubject?subjectId=${payload.id}`);

            context.commit('REMOVE_SUBJECT', payload)
        },
        toggleUnitDialog(context, payload) {
            context.commit('OPEN_UNIT_DIALOG', payload)
        },
        async fetchUnits(context) {
            const { data } = await axiosIns.get('/Unit/GetUnits?unitType=0');
            context.commit('GET_UNITS_ITEMS', data);
        },
        unitsPagination(context, currentPage) {
            context.commit("UNITS_PAGINATION", currentPage)
        },
        unitsListPagination(context, currentPage) {
            context.commit("UNITS_LIST_PAGINATION", currentPage)
        },
        lessonsListPagination(context, currentPage) {
            context.commit("LESSONS_LIST_PAGINATION", currentPage)
        },
        async getUnitsList(context) {
            const { data } = await axiosIns.get('/Unit/UnitsList');
            context.commit('UNITS_LIST', data);
        },
        async fetchUnitDetails(context,payload)
        {
            const { data } = await axiosIns.get(`/Unit/GetUnitDetails?unitId=${payload}`);

            context.commit('GET_UNIT_DETAILS', data);
        },
        async setUnit(context,payload)
        {
            console.log(payload);
            const {data} = await axiosIns.post('/unit/SetUnit',{
                
                Id: payload.id,
                Name: payload.name,
                Description : payload.description,
                Price: +payload.price,
                UnitType:payload.unitType,
                UnitOrder: +payload.unitOrder,
                IsFree: payload.isFree,
                SubjectId:payload.subjectId,
                TeacherId:payload.teacherId,
            });
            if (payload.id == 0) {
                context.commit("ADD_UNIT_ITEM", data);
            } else {
                context.commit("UPDATE_UNIT_ITEM", data);
            }
        },
        async removeUnits(context, payload) {
            console.log(payload);
            const { data } = await axiosIns.put('/Unit/removeUnits', payload);

            if (data == 0) {
                context.commit("CANT_REMOVE_UNKNOWN")
            }
            else if(data == 1) {
                context.commit('CANT_REMOVE_KNOWN', payload)
            }
            else if(data == 2){
                context.commit('CANT_MULTI_REMOVE_KNOWN')
            }
            else{
                context.commit('REMOVE_UNITS', payload)
            }
        },
        async removeUnit(context, payload) {
            const { data } = await axiosIns.put('/Unit/removeUnits', [payload]);
            if (data == 0) {
                context.commit("CANT_REMOVE_UNKNOWN")
            }
            else if(data == 1) {
                context.commit('CANT_REMOVE_KNOWN')
            }
            else{
                context.commit('REMOVE_UNIT') 
            }
        },
        async getTeachersList(context) {
            const { data } = await axiosIns.get('/User/TeachersList');
            context.commit('TEACHERS_LIST', data);
        },
        async filterSubjects({state}) {
            state.searchSubjectsList = await store.dispatch('app/filterItems', {items: 'subjects', key: 'name'})
            store.dispatch("subjectsPagination", 1);
        },
        async filterUnits({state}) {
            state.searchUnitsList = await store.dispatch('app/filterItems', {items: 'units', key: 'name'})
            store.dispatch("unitsPagination", 1);
        },
    },
}