import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Unicon from 'vue-unicons'
import {
  uniBooks,
  uniFileQuestionAlt,
  uniQuestionCircle,
  uniMegaphone,
  uniBookmark,
  uniHomeAlt,
  uniBell,
  uniQrcodeScan,
  uniBill,
  uniUsersAlt,
  uniUserCircle,
  uniMoneyWithdrawal,
  uniEnvelopeQuestion,
  uniUserSquare,
  uniMoneybag,
  uniSearch,
  uniCircle,
  uniMoon,
  uniSun,
  uniAngleRight,
  uniAngleLeft,
  uniEllipsisV,
  uniTrashAlt,
  uniPen,
  uniBox,
  uniPlus,
  uniVideo,
  uniTicket,
  uniCommentAltDots,
  uniNotebooks,
  uniText,
  uniArrowLeft,
  uniUser,
  uniTimes,
  uniAngleDown,
  uniAngleUp
} from 'vue-unicons/src/icons'

Unicon.add([
  uniBooks,
  uniFileQuestionAlt,
  uniQuestionCircle,
  uniMegaphone,
  uniBookmark,
  uniHomeAlt,
  uniBell,
  uniQrcodeScan,
  uniBill,
  uniUsersAlt,
  uniUserCircle,
  uniMoneyWithdrawal,
  uniEnvelopeQuestion,
  uniUserSquare,
  uniMoneybag,
  uniSearch,
  uniCircle,
  uniMoon,
  uniSun,
  uniAngleRight,
  uniAngleLeft,
  uniEllipsisV,
  uniTrashAlt,
  uniPen,
  uniBox,
  uniPlus,
  uniVideo,
  uniTicket,
  uniCommentAltDots,
  uniNotebooks,
  uniText,
  uniArrowLeft,
  uniUser,
  uniTimes,
  uniAngleDown,
  uniAngleUp
])
Vue.use(Unicon)
Vue.component(FeatherIcon.name, FeatherIcon)
