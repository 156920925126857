import axiosIns from '@axios';
import store from '@/store';
import Swal from "sweetalert2";

export default {
    state: {
        isSessionDialog: false,
        sessionsActivePage: [],
        sessionsFirstPage: 1,
        sessionPageLength: 8,
        sessionLessonPageLength: 6,
        sessionLessonsActivePage: [],
        sessionLessonsFirstPage: 1,
        examSessions: [],
        mainSessionList: [],
        sessionSubjectsList:[],
        sessionTeachersList:[],
        sessionForm: {
            id: 0,
            name: "",
            unitLessons:[],
            description: "",
            subjectId: null,
            teacherId: null,
            price: null,
            unitType: 1,
            unitOrder: null,
            isFree: false,
            subscriptionsCount: 0,
            lessonsCount: 0,
            videosCount: 0
        }
    },
    getters: {
        isSessionDialog(state) {
            return state.isSessionDialog;
        },
        sessionsActivePage(state) {
            return state.sessionsActivePage;
        },
        sessionsFirstPage(state) {
            return state.sessionsFirstPage;
        },
        sessionPageLength(state) {
            return state.sessionPageLength;
        },
        examSessions(state) {
            return state.examSessions;
        },
        sessionForm(state) {
            return state.sessionForm;
        },
        sessionSubjectsList(state) {
            return state.sessionSubjectsList;
        },
        sessionTeachersList(state) {
            return state.sessionTeachersList;
        },
        sessionLessonPageLength(state) {
            return state.sessionLessonPageLength;
        },
        sessionLessonsActivePage(state) {
            return state.sessionLessonsActivePage;
        },
        sessionLessonsFirstPage(state) {
            return state.sessionLessonsFirstPage;
        },
        mainSessionList(state) {
            return state.mainSessionList
        }
    },
    mutations: {
        OPEN_SESSION_DIALOG(state, payload) {
            state.isSessionDialog = payload;
        },
        SESSIONS_PAGINATION(state, currentPage)
        {
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.sessionPageLength,
                items: state.mainSessionList
            })
            .then(data => {
                state.sessionsActivePage = data;
                state.sessionsFirstPage = currentPage;
            })
        },
        GET_EXAM_SESSIONS(state, payload) {
            state.examSessions = payload;
            state.mainSessionList = [...payload];
            state.sessionForm = {
                id: 0,
                name: "",
                unitLessons:[],
                description: "",
                subjectId: null,
                teacherId: null,
                price: null,
                unitType: 1,
                unitOrder: null,
                isFree: false,
                subscriptionsCount: 0,
                lessonsCount: 0,
                videosCount: 0
            };
            store.dispatch("sessionsPagination", 1);
        },
        ADD_SESSION_ITEM(state,payload)
        {
            state.examSessions.unshift(payload);
            state.mainSessionList.unshift(payload);
            state.sessionForm = {
                id: 0,
                name: "",
                unitLessons:[],
                description: "",
                subjectId: null,
                teacherId: null,
                price: null,
                unitType: 1,
                unitOrder: null,
                isFree: false,
                subscriptionsCount: 0,
                lessonsCount: 0,
                videosCount: 0
            };
            store.dispatch("sessionsPagination", 1);
        },
        UPDATE_SESSION_ITEM()
        {
            store.dispatch("sessionsPagination", 1);
        },
        SESSION_LESSONS_PAGINATION(state, currentPage)
        {
            store
            .dispatch("app/pageChanged", {
                currentPage: currentPage,
                pageLength: state.sessionLessonPageLength,
                items: state.sessionForm.unitLessons
            })
            .then(data => {
                state.sessionLessonsActivePage = data;
                state.sessionLessonsFirstPage = currentPage;
            })
        },
        GET_SESSION_DETAILS(state, payload)
        {
            Object.assign(state.sessionForm, payload.unitDto);
            state.sessionSubjectsList = payload.subjectsList;
            state.sessionTeachersList = payload.teachersList;
            store.dispatch("sessionLessonsPagination", 1);
        },
        REMOVE_SESSION(state, id)
        {
            const index = state.examSessions.findIndex(item => item.id == id);
            state.examSessions.splice(index, 1);
            const indexMainSessionList = state.mainSessionList.findIndex(item => item.id == id);
            state.mainSessionList.splice(indexMainSessionList, 1);
            
            Swal.fire({
                title: "تم الحذف !",
                text: `تم حذف الجلسة بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            store.dispatch("sessionsPagination", 1);
        },
        // CANT_REMOVE(state,payload) {
        //     Swal.fire({
        //         title: "لا يمكن الحذف !",
        //         text: `${payload}`,
        //         icon: "error",
        //         showConfirmButton: false,
        //         timer: 2000
        //     });
        // },
    },
    actions: {
        toggleSessionDialog(context, payload) {
            context.commit('OPEN_SESSION_DIALOG', payload);
        },
        sessionsPagination(context, currentPage) {
            context.commit("SESSIONS_PAGINATION", currentPage)
        },
        sessionLessonsPagination(context, currentPage) {
            context.commit("SESSION_LESSONS_PAGINATION", currentPage)
        },
        async fetchExamSessions(context)
        {
            const { data } = await axiosIns.get('/Unit/GetUnits?unitType=1');
            context.commit('GET_EXAM_SESSIONS', data);
        },
        async setExamSession(context, payload)
        {
            const {data} = await axiosIns.post('/unit/SetUnit',{
                Id: payload.id,
                Name: payload.name,
                Description : payload.description,
                Price: +payload.price,
                UnitType:payload.unitType,
                UnitOrder: +payload.unitOrder,
                IsFree: payload.isFree,
                SubjectId:payload.subjectId,
                TeacherId:payload.teacherId,
            });
            if (payload.id == 0) {
                context.commit("ADD_SESSION_ITEM", data);
            } else {
                context.commit("UPDATE_SESSION_ITEM", data);
            }
        },
        async fetchSessionDetails(context,payload)
        {
            const { data } = await axiosIns.get(`/Unit/GetUnitDetails?unitId=${payload}`);

            context.commit('GET_SESSION_DETAILS', data);
        },
        async removeSession(context, payload) {
            const { data } = await axiosIns.put('/Unit/removeUnits', payload);

            if (data != true) {
                context.commit("CANT_REMOVE", data)
            }
            else {
                context.commit('REMOVE_SESSION', payload)
            }
        },
        async filterExamSession({state}) {
            state.mainSessionList = await store.dispatch('app/filterItems', {items: 'examSessions', key: 'name'})
            store.dispatch("sessionsPagination", 1);
        }
    }
}